import { FC } from 'react';

interface Participant {
    id: string;
    emailAddress?: string;
    photo?: string; // URL of the user's avatar
    fullName?: string; // Full name of the user
    department?: string; // Department of the user
    userPrincipalName?: string; // User principal name
    onPremisesUserPrincipalName?: string; // On-premises user principal name
    onPremisesDomainName?: string; // On-premises domain name
    manager: string; // User's manager
    availability?: string;
    activity?: string;
}

interface UserStatusProps {
    participant: Participant;
    persona: boolean;
}

const UserStatus: FC<UserStatusProps> = ({ participant, persona }) => {
    let positionClass = 'presico';
    if (persona) {
        positionClass = 'presicop';
    }
    const presenceIcon = () => {
        switch (participant.availability) {
            case 'Available':
                return <div className={positionClass + ' pres-av'} />;
            case 'Away':
                return <div className={positionClass + ' pres-aw'} />;
            case 'BeRightBack':
                return <div className={positionClass + ' pres-aw'} />;
            case 'Busy':
                return <div className={positionClass + ' pres-r'} />;
            case 'DoNotDisturb':
                return <div className={positionClass + ' pres-r'} />;
            case 'Offline':
                return <div className={positionClass + ' pres-o'} />;
            case 'PresenceUnknown':
                return <div className={positionClass + ' pres-o'} />;
            default:
                return <div className={positionClass + ' pres-o'} />;
        }
    };

    return presenceIcon();
};

export default UserStatus;
