import { Button } from '@fluentui/react-button';
import { Tooltip, makeStyles } from '@fluentui/react-components';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';
import { useEffect, useState } from 'react';
import { useChat } from '../../../../libs/hooks';
import { getFriendlyChatName } from '../../../../libs/hooks/useChat';
import { useAppSelector } from '../../../../redux/app/hooks';
import { Delete16 } from '../../../shared/BundledIcons';

const useClasses = makeStyles({
    root: {
        width: '500px',
    },
    actions: {
        paddingTop: '10%',
    },
});

interface IEditChatNameProps {
    chatId: string;
}

export const DeleteChatDialog: React.FC<IEditChatNameProps> = ({ chatId }) => {
    const classes = useClasses();
    const chat = useChat();

    const { conversations } = useAppSelector((state) => state.conversations);
    const chatName = getFriendlyChatName(conversations[chatId]);

    const onDeleteChat = () => {
        void chat.deleteChat(chatId);
    };

    const [currentFont, setCurrentFont] = useState(localStorage.getItem('selectedFont') ?? 'fontMontserrat');

    // Effect to update the font based on localStorage changes
    useEffect(() => {
        const updateFont = (event: CustomEvent<string>) => {
            setCurrentFont(event.detail);
        };

        window.addEventListener('fontChange', updateFont as EventListener);

        // Initialize the font
        const storedFont = localStorage.getItem('selectedFont') ?? 'fontMontserrat';
        setCurrentFont(storedFont);

        return () => {
            window.removeEventListener('fontChange', updateFont as EventListener);
        };
    }, []);

    return (
        <Dialog modalType="alert">
            <DialogTrigger>
                <Tooltip content={'Delete chat session'} relationship="label">
                    <Button icon={<Delete16 />} appearance="transparent" aria-label="Edit" />
                </Tooltip>
            </DialogTrigger>
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle>
                        <span className={currentFont}>Are you sure you want to delete chat:</span>
                        <br /> <span className={`${currentFont} hlct`}>{chatName}?</span>
                        <hr />
                    </DialogTitle>
                    <DialogContent>
                        <span className={currentFont}>
                            This action will permanently delete the chat, and any associated resources and memories. Are
                            you sure you wish to continue?
                        </span>
                    </DialogContent>
                    <DialogActions className={classes.actions}>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button appearance="secondary" className={currentFont}>
                                Cancel
                            </Button>
                        </DialogTrigger>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button appearance="primary" className={currentFont} onClick={onDeleteChat}>
                                Delete
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
