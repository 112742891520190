import { CaretDown20Regular, CaretUp20Regular } from '@fluentui/react-icons';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

// Define interfaces
interface QnA {
    question: string;
    answer: string;
    opened?: boolean;
}

interface Category {
    name: string;
    qna: QnA[];
}

interface GuideData {
    categories: Category[];
}

interface PossibleGuideData {
    categories?: Array<{
        name: string;
        qna: Array<{ question: string; answer: string }>;
    }>;
}

export const GuideTab: React.FC = () => {
    const [language, setLanguage] = useState<string>('us-en');
    const [loading, setLoading] = useState<boolean>(false);
    const [guideData, setGuideData] = useState<GuideData | null>(null);

    const renderMarkdown = (markdownText: string) => {
        return <ReactMarkdown remarkPlugins={[remarkGfm]}>{markdownText}</ReactMarkdown>;
    };

    const fetchGuideData = async (lang: string) => {
        setLoading(true);
        try {
            // console.log('Attempting to load guide_' + lang + '.json...');
            const response = await fetch(`/assets/guides/guide_${lang}.json`);
            const jsonData = (await response.json()) as PossibleGuideData;

            // Validate jsonData to be of type GuideData
            if (!jsonData.categories || !Array.isArray(jsonData.categories)) {
                throw new Error('Invalid guide data format');
            }

            // Type assertion
            const data: GuideData = jsonData as GuideData;
            data.categories.forEach((category) => {
                category.qna.forEach((qa) => (qa.opened = false));
            });
            setGuideData(data);
            setAllExpanded(false);
        } catch (error) {
            console.error('Failed to fetch guide data:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        void fetchGuideData(language);
    }, [language]);

    useEffect(() => {
        const observer = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    const links = document.querySelectorAll('a[href]');
                    links.forEach((link) => {
                        link.setAttribute('target', '_blank');
                        link.setAttribute('rel', 'noopener noreferrer');
                    });
                }
            }
        });

        observer.observe(document.body, { childList: true, subtree: true });

        return () => {
            observer.disconnect();
        };
    }, []);

    const toggleAnswer = (catIndex: number, qaIndex: number) => {
        if (guideData) {
            const newData = { ...guideData };
            newData.categories[catIndex].qna[qaIndex].opened = !newData.categories[catIndex].qna[qaIndex].opened;
            setGuideData(newData);
        }
    };

    const [currentFont, setCurrentFont] = useState(localStorage.getItem('selectedFont') ?? 'fontMontserrat');

    // Effect to update the font based on localStorage changes
    useEffect(() => {
        const updateFont = (event: CustomEvent<string>) => {
            setCurrentFont(event.detail);
        };

        window.addEventListener('fontChange', updateFont as EventListener);

        // Initialize the font
        const storedFont = localStorage.getItem('selectedFont') ?? 'fontMontserrat';
        setCurrentFont(storedFont);

        return () => {
            window.removeEventListener('fontChange', updateFont as EventListener);
        };
    }, []);

    const [allExpanded, setAllExpanded] = useState(false);

    const toggleAllAnswers = () => {
        if (guideData) {
            const newData = { ...guideData };
            newData.categories.forEach((category) => {
                category.qna.forEach((qa) => {
                    qa.opened = !allExpanded;
                });
            });
            setGuideData(newData);
            setAllExpanded(!allExpanded);
        }
    };

    return (
        <div className={`${currentFont} utverseGuideContainer`}>
            <h2>UT Verse Guide</h2>
            Language{' '}
            <select
                title="setLanguage"
                value={language}
                onChange={(e) => {
                    setLanguage(e.target.value);
                }}
            >
                <option value="us-en">English</option>
                <option value="fr">Français</option>
                <option value="ru">Русский</option>
                <option value="es">Español</option>
                <option value="jp">日本語</option>
                <option value="cn">中文</option>
            </select>
            <button
                className={allExpanded ? 'guideButtonCommonactivated' : 'guideButtonCommon'}
                onClick={toggleAllAnswers}
            >
                {allExpanded ? 'Collapse All Answers' : 'Expand All Answers'}
            </button>
            {loading ? (
                <p>Now loading the guide... Please wait!</p>
            ) : (
                guideData?.categories.map((category, catIndex) => (
                    <div key={catIndex}>
                        <h2>{category.name}</h2>
                        <div className="qacontainer">
                            {category.qna.map((qa, qaIndex) => (
                                <div key={qaIndex}>
                                    <div
                                        className={
                                            qa.opened ? 'qnaheaderbutton qnaheaderbuttonActive' : 'qnaheaderbutton'
                                        }
                                        onClick={() => {
                                            toggleAnswer(catIndex, qaIndex);
                                        }}
                                    >
                                        <div>{qa.question}</div>
                                        <div>{qa.opened ? <CaretUp20Regular /> : <CaretDown20Regular />}</div>
                                    </div>
                                    <p className={qa.opened ? 'qnaOpened' : 'qnaAnswer'}>
                                        {' '}
                                        <div>{renderMarkdown(qa.answer)}</div>
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                ))
            )}
        </div>
    );
};
