import { ArrowLeft16Regular, ArrowRight16Regular } from '@fluentui/react-icons';
import React from 'react';

interface Translation {
    time: string;
    fromLang: string;
    toLang: string;
    text: string;
    right: boolean;
}

interface TranslationHistoryProps {
    history: Translation[];
}

const TranslationHistory: React.FC<TranslationHistoryProps> = ({ history }) => {
    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const formatter = new Intl.DateTimeFormat('en-US', {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
        });
        return formatter.format(date);
    };

    return (
        <div className="utversal_historyContainer">
            <div className="utversal_historyTitle">Translation History</div>
            <div className="utversal_historyInner">
                {history.map((item, index) => {
                    // Remove the quotes if they exist
                    const fromLanguage = item.fromLang.replace(/^['"]+|['"]+$/g, '');
                    const toLanguage = item.toLang.replace(/^['"]+|['"]+$/g, '');

                    return (
                        <div key={index} className="utversal_historyItem utversal_slide-in">
                            <div className="utversal_hitime">{formatDate(item.time)}</div>
                            <div className="utversal_hifrom">{fromLanguage}</div>
                            <div className="utversal_hiarrow">
                                {item.right ? <ArrowLeft16Regular /> : <ArrowRight16Regular />}
                            </div>
                            <div className="utversal_hito">{toLanguage}</div>
                            <div className="utversal_histring">
                                {item.text.length > 128 ? `${item.text.substring(0, 125)}...` : item.text}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default TranslationHistory;
