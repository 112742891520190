import { useMsal } from '@azure/msal-react';
import {
    ArrowFlowUpRight24Filled,
    ArrowSwap20Regular,
    Broom20Regular,
    Eraser20Regular,
    MicRegular,
    Pause16Regular,
    Play16Regular,
    Stop16Filled,
} from '@fluentui/react-icons';
import * as speechSdk from 'microsoft-cognitiveservices-speech-sdk';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../../AppContext';
import { AuthHelper } from '../../../libs/auth/AuthHelper';
import { SpeechService } from '../../../libs/services/SpeechService';
import TranslationHistory from './TranslationHistory';

interface Translation {
    time: string;
    fromLang: string;
    toLang: string;
    text: string;
    right: boolean;
}

interface LanguageOption {
    value: string;
    label: string;
}

const speechService = new SpeechService();

const languages = [
    { value: 'English', label: 'English (English)' },
    { value: 'Français', label: 'Français (French)' },
    { value: 'Español', label: 'Español (Spanish)' },
    { value: 'Italiano', label: 'Italiano (Italian)' },
    { value: 'Deutsch', label: 'Deutsch (German)' },
    { value: '日本語', label: '日本語 (Japanese)' },
    { value: '한국어', label: '한국어 (Korean)' },
    { value: 'Русский', label: 'Русский (Russian)' },
    { value: 'Gaeilge', label: 'Gaeilge (Irish)' },
    { value: 'Latvian', label: 'Latvian (Latvian)' },
    { value: 'עברית', label: 'עברית (Hebrew)' },
    { value: 'हिन्दी', label: 'हिन्दी (Hindi)' },
    { value: '中文', label: '中文 (Chinese)' },
    { value: '台灣話', label: '台灣話 (Taiwanese)' },
    { value: 'Українська', label: 'Українська (Ukrainian)' },
    { value: 'Filipino', label: 'Filipino (Filipino)' },
    { value: 'Português', label: 'Português (Portuguese)' },
    { value: 'فارسی', label: 'فارسی (Persian)' },
    { value: 'العربية', label: 'العربية (Arabic)' },
    { value: 'اُردُو', label: 'اُردُو (Urdu)' },
    { value: 'Afrikaans', label: 'Afrikaans (Afrikaans)' },
    { value: 'Vietnamese', label: 'Vietnamese (Vietnamese)' },
    { value: 'Zulu', label: 'Zulu (Zulu)' },
];

const languagesNoVoice: string[] = ['Latvian', '中文', '台灣話', 'Українська', 'فارسی', 'Vietnamese'];

const TranslateTab = () => {
    const [inputText, setInputText] = useState('');
    const [translatedText, setTranslatedText] = useState('');
    const [history, setHistory] = useState<Translation[]>([]);
    const [fromLang, setFromLang] = useState(localStorage.getItem('activeFromLang') ?? 'English');
    const [toLang, setToLang] = useState(localStorage.getItem('activeToLang') ?? 'Español');
    const initialFromLangs = JSON.parse(localStorage.getItem('usedFromLanguages') ?? '["English"]') as string;
    const initialToLangs = JSON.parse(localStorage.getItem('usedToLanguages') ?? '["Español"]') as string;
    let cannotbevoicedto = languagesNoVoice.includes(toLang);
    let cannotbevoicedfrom = languagesNoVoice.includes(fromLang);
    const [usedFromLanguages, setUsedFromLanguages] = useState(new Set(initialFromLangs));
    const [usedToLanguages, setUsedToLanguages] = useState(new Set(initialToLangs));
    const { instance, inProgress } = useMsal();
    const [isPlaying, setIsPlaying] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [_isTextToSpeechDisabled, setIsTextToSpeechDisabled] = useState(false);
    const [isCharLimitExceeded, setIsCharLimitExceeded] = useState(false);
    const [activeMode, setActiveMode] = useState('TO'); // Used for toggling conversation between From and to
    const [isTranslating, setIsTranslating] = useState(false);

    // Genders for voice
    const [genderVoiceTA, setGenderVoiceTA] = useState(localStorage.getItem('genderVoiceTA') ?? 'Male');
    const [genderVoiceTB, setGenderVoiceTB] = useState(localStorage.getItem('genderVoiceTB') ?? 'Male');

    // Is Recording?
    const [isRecording, setIsRecording] = useState(false);

    // Conversation Mode
    const [conversationalMode, setConversationalMode] = useState(localStorage.getItem('conversationalMode') === 'true');

    // Gender Toggle
    const toggleGenderA = () => {
        const newGender = genderVoiceTA === 'Male' ? 'Female' : 'Male';
        setGenderVoiceTA(newGender);
        localStorage.setItem('genderVoiceTA', newGender); // Save to localStorage
    };

    const toggleGenderB = () => {
        const newGender = genderVoiceTB === 'Male' ? 'Female' : 'Male';
        setGenderVoiceTB(newGender);
        localStorage.setItem('genderVoiceTB', newGender); // Save to localStorage
    };

    // Update state based on text length
    useEffect(() => {
        setIsCharLimitExceeded(translatedText.length > 10000);
    }, [translatedText]);

    // Initialize the autoplayback state
    const [autoplayback, setAutoplayback] = useState(localStorage.getItem('autoplayback') === 'true');

    // Update localStorage when autoplayback changes
    useEffect(() => {
        localStorage.setItem('autoplayback', autoplayback.toString()); // Convert boolean to string
    }, [autoplayback]);

    // Handle change in checkbox
    const handleAutoplaybackChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Specify type for event
        setAutoplayback(event.target.checked);
    };

    // Function to handle checkbox change
    const handleConversationalModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        setConversationalMode(isChecked);
    };

    // Function to toggle between 'FROM' and 'TO' modes
    const toggleConversationMode = () => {
        setActiveMode(activeMode === 'FROM' ? 'TO' : 'FROM');
    };

    // Save conversationalMode to localStorage whenever it changes
    // Save conversationalMode to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('conversationalMode', String(conversationalMode)); // Convert to string
    }, [conversationalMode]);

    // Set current section
    const { setCurrentSection } = useContext(AppContext);

    useEffect(() => {
        setCurrentSection('translator');
    }, [setCurrentSection]);

    // Recording
    const startRecording = async (recognizer: speechSdk.SpeechRecognizer, isFromLang: boolean): Promise<void> => {
        setIsRecording(true);

        try {
            await new Promise<void>((resolve, reject) => {
                recognizer.recognizeOnceAsync(
                    (result) => {
                        // Check if result.text is defined
                        if (result.text && result.text.trim() !== '') {
                            if (isFromLang) {
                                setInputText(result.text);
                                if (autoSendEnabled) {
                                    translateTextWithInput(result.text).catch((error) => {
                                        console.error('Error during translation:', error);
                                    });
                                }
                            } else {
                                setTranslatedText(result.text);
                                if (autoSendEnabled) {
                                    translateTextWithInput(result.text).catch((error) => {
                                        console.error('Error during translation:', error);
                                    });
                                }
                            }
                        }
                        setIsRecording(false);
                        resolve();
                    },
                    (error) => {
                        console.error('Recognition error: ', error);
                        setIsRecording(false);
                        reject(new Error(error));
                    },
                );
            });
        } catch (error) {
            console.error('Error during start recording:', error);
        }
    };

    const stopRecording = async (recognizer: speechSdk.SpeechRecognizer): Promise<void> => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (recognizer && isRecording) {
            // Convert the callback-based stopContinuousRecognitionAsync to a promise-based approach
            try {
                await new Promise<void>((resolve, reject) => {
                    recognizer.stopContinuousRecognitionAsync(
                        () => {
                            resolve();
                        },
                        (error) => {
                            console.error('error: ', error);
                            reject(new Error(error));
                        },
                    );
                });

                console.log('Recording stopped.');
                setIsRecording(false);
            } catch (error) {
                console.error('Stop recording error: ', error);
            }
        }
    };

    // Function to enable or disable text areas and microphone buttons based on the active mode
    const toggleModeElements = () => {
        if (activeMode === 'FROM') {
            // 'FROM' mode is active
            // Enable 'FROM' textarea and microphone button
            // Disable 'TO' textarea and microphone button
            setIsDisabled(false); // Enable 'FROM' textarea
            setIsTextToSpeechDisabled(true); // Disable 'TO' textarea
            setIsPlaying(false); // Disable 'FROM' microphone button
            setIsPaused(false); // Disable 'FROM' microphone button
            // console.log('Active mode set to FROM');
        } else {
            // 'TO' mode is active
            // Enable 'TO' textarea and microphone button
            // Disable 'FROM' textarea and microphone button
            setIsDisabled(true); // Disable 'FROM' textarea
            setIsTextToSpeechDisabled(false); // Enable 'TO' textarea
            setIsPlaying(false); // Disable 'TO' microphone button
            setIsPaused(false); // Disable 'TO' microphone button
            //   console.log('Active mode set to TO');
        }
    };

    // Toggle mode and update elements when the button is clicked
    const handleToggleModeClick = () => {
        toggleConversationMode();
        toggleModeElements();
    };

    // Fetch Gender from localStorage, default to "Female" if not set
    //const selectedVoice = localStorage.getItem('selectedVoice') ?? 'Female';

    // Fetch Speed from localStorage, default to "medium" if not set
    const selectedVoiceSpeed = localStorage.getItem('selectedVoiceSpeed') ?? 'medium';

    // Fetch Pitch from localStorage, default to "medium" if not set
    const voicePitch = localStorage.getItem('voicePitch') ?? 'medium';

    useEffect(() => {
        const savedHistory = JSON.parse(localStorage.getItem('translationHistory') ?? '[]') as Translation[];
        savedHistory.sort((a, b) => b.time.localeCompare(a.time));
        setHistory(savedHistory);
    }, []);

    const updateLocalStorage = (key: string, value: unknown) => {
        try {
            localStorage.setItem(key, JSON.stringify(value)); // Convert to JSON string without quotes
        } catch (error) {
            console.error('Error writing to local storage', error);
        }
    };

    const manageLanguageTabs = (newLang: string, setLanguages: React.Dispatch<React.SetStateAction<Set<string>>>) => {
        // console.log('New language: ' + newLang);
        setLanguages((prevLanguages) => {
            const updatedLanguages = new Set(prevLanguages);
            // Add the new language
            updatedLanguages.add(newLang);
            // If the limit is exceeded, remove the oldest language
            if (updatedLanguages.size > 4) {
                const oldestLang = Array.from(updatedLanguages).shift(); // Get the first/oldest language
                if (oldestLang) {
                    updatedLanguages.delete(oldestLang);
                }
            }

            return updatedLanguages;
        });
    };

    const swapLanguages = () => {
        setInputText(translatedText);
        setTranslatedText(inputText);

        // Manage 'from' and 'to' languages tabs
        manageLanguageTabs(toLang, setUsedFromLanguages);
        manageLanguageTabs(fromLang, setUsedToLanguages);

        setFromLang(toLang);
        setToLang(fromLang);

        // Update localStorage
        updateLocalStorage('activeFromLang', toLang);
        updateLocalStorage('activeToLang', fromLang);
        updateLocalStorage('usedFromLanguages', Array.from(usedFromLanguages));
        updateLocalStorage('usedToLanguages', Array.from(usedToLanguages));
    };

    const updateFromLang = (newLang: string) => {
        setFromLang(newLang);
        cannotbevoicedfrom = languagesNoVoice.includes(newLang);
        manageLanguageTabs(newLang, setUsedFromLanguages);
        updateLocalStorage('activeFromLang', newLang);
    };

    const updateToLang = (newLang: string) => {
        setToLang(newLang);
        cannotbevoicedto = languagesNoVoice.includes(newLang);
        manageLanguageTabs(newLang, setUsedToLanguages);
        updateLocalStorage('activeToLang', newLang);
    };

    useEffect(() => {
        localStorage.setItem('usedFromLanguages', JSON.stringify(Array.from(usedFromLanguages)));
    }, [usedFromLanguages]);

    useEffect(() => {
        localStorage.setItem('usedToLanguages', JSON.stringify(Array.from(usedToLanguages)));
    }, [usedToLanguages]);

    const translateText = async (text: string) => {
        // console.log('translation activated');
        if (!text.trim()) return;

        try {
            if (activeMode === 'TO') {
                const translated = await speechService.translateText(text, toLang);
                setTranslatedText(translated);
                const newHistoryItem = {
                    time: new Date().toISOString(),
                    fromLang,
                    toLang,
                    text: translated,
                    right: false,
                };
                const newHistory = [newHistoryItem, ...history].slice(0, 10);
                setHistory(newHistory);
                updateLocalStorage('translationHistory', newHistory);
                // console.log('Translated to: ' + translated);
                if (autoplayback) {
                    handlePlayClick(true, translated).catch((error) => {
                        console.error('Failed to handle text-to-speech:', error);
                    });
                }
            } else {
                const translated = await speechService.translateText(text, fromLang);
                setInputText(translated);
                const newHistoryItem = {
                    time: new Date().toISOString(),
                    toLang,
                    fromLang,
                    text: translated,
                    right: true,
                };
                const newHistory = [newHistoryItem, ...history].slice(0, 10);
                setHistory(newHistory);
                updateLocalStorage('translationHistory', newHistory);
                //console.log('Translated to b: ' + translated);
                if (autoplayback) {
                    handlePlayClick(false, translated).catch((error) => {
                        console.error('Failed to handle text-to-speech:', error);
                    });
                }
            }
            if (conversationalMode) {
                handleToggleModeClick();
            }
        } catch (error) {
            console.error('Error translating text:', error);
        }
    };

    const translateTextWithInput = async (text: string) => {
        // console.log('translation activated');
        if (!text.trim()) return;

        try {
            if (activeMode === 'TO') {
                // This is when we go from the FROM textbox and translate/TTS to the TO box. Visual: [ FROM ] [ TO ] >>>
                // console.log('+++++++++++ TO');
                ///   console.log('+++++++++++ TRANSLATING TEXT: ' + text);
                const translated = await speechService.translateText(text, toLang);
                //   console.log('+++++++++++ TRANSLATED TEXT: ' + translated);
                setTranslatedText(translated);
                const newHistoryItem = {
                    time: new Date().toISOString(),
                    fromLang,
                    toLang,
                    text: translated,
                    right: false,
                };
                const newHistory = [newHistoryItem, ...history].slice(0, 10);
                setHistory(newHistory);
                updateLocalStorage('translationHistory', newHistory);
                //   console.log('Translated to: ' + translated);

                if (autoplayback) {
                    handlePlayClick(true, translated).catch((error) => {
                        console.error('Failed to handle text-to-speech:', error);
                    });
                }
            } else {
                // This is when we go from the TO textbox and translate/TTS to the FROM box. Visual: [ FROM ] [ TO ] <<<
                //  console.log('+++++++++++ FROM');
                //  console.log('+++++++++++ TRANSLATING TEXT: ' + text);
                const translated = await speechService.translateText(text, fromLang);
                //   console.log('+++++++++++ TRANSLATED TEXT: ' + translated);
                setInputText(translated);
                const newHistoryItem = {
                    time: new Date().toISOString(),
                    toLang,
                    fromLang,
                    text: translated,
                    right: true,
                };
                const newHistory = [newHistoryItem, ...history].slice(0, 10);
                setHistory(newHistory);
                updateLocalStorage('translationHistory', newHistory);
                //    console.log('Translated to b: ' + translated);
                if (autoplayback) {
                    handlePlayClick(false, translated).catch((error) => {
                        console.error('Failed to handle text-to-speech:', error);
                    });
                }
            }

            if (conversationalMode) {
                handleToggleModeClick();
            }
        } catch (error) {
            console.error('Error translating text:', error);
        }
    };

    const handleTranslateClick = () => {
        setIsTranslating(true); // Start translation

        const textToTranslate = activeMode === 'TO' ? inputText : translatedText;

        translateText(textToTranslate)
            .catch((error) => {
                console.error('Error during translation:', error);
            })
            .finally(() => {
                setIsTranslating(false); // End translation
            });
    };

    // Function to handle tab click for 'from' languages
    const handleFromLangTabClick = (lang: string) => {
        if (lang !== fromLang) {
            updateFromLang(lang);
        }
    };

    // Function to handle tab click for 'to' languages
    const handleToLangTabClick = (lang: string) => {
        if (lang !== toLang) {
            updateToLang(lang);
        }
    };

    const renderLanguageOptions = (languages: LanguageOption[]) => {
        return languages.map((lang) => (
            <option key={lang.value} value={lang.value}>
                {lang.label}
            </option>
        ));
    };

    // Speech Recognition
    const [_fromRecognizer, setFromRecognizer] = useState<speechSdk.SpeechRecognizer>();
    const [_toRecognizer, setToRecognizer] = useState<speechSdk.SpeechRecognizer>();
    const [autoSendEnabled, setAutoSendEnabled] = useState<boolean>(() => {
        const savedAutoSendEnabled = localStorage.getItem('autoSendEnabled');
        if (savedAutoSendEnabled !== null) {
            try {
                // Use a type assertion here
                const parsedValue = JSON.parse(savedAutoSendEnabled) as boolean;
                // No need to check 'typeof parsedValue === "boolean"' because of the assertion
                return parsedValue;
            } catch (error) {
                console.error('Parsing error: ', error);
            }
        }
        return false;
    });

    // From and to lists auto load
    // Update 'fromLang' when the corresponding local storage value changes
    useEffect(() => {
        const storedFromLang = localStorage.getItem('activeFromLang');
        if (storedFromLang) {
            setFromLang(String(JSON.parse(storedFromLang))); // Cast the parsed value to string
        }
    }, []);

    // Update 'toLang' when the corresponding local storage value changes
    useEffect(() => {
        const storedToLang = localStorage.getItem('activeToLang');
        if (storedToLang) {
            setToLang(String(JSON.parse(storedToLang))); // Cast the parsed value to string
        }
    }, []);

    // Load from localStorage when component mounts
    useEffect(() => {
        const savedAutoSendEnabled = localStorage.getItem('autoSendEnabled');
        if (savedAutoSendEnabled !== null) {
            try {
                const parsedValue = JSON.parse(savedAutoSendEnabled) as boolean;
                //  console.log(parsedValue);
                if (typeof parsedValue === 'boolean') {
                    setAutoSendEnabled(parsedValue);
                }
            } catch (error) {
                console.error('Parsing error: ', error);
                setAutoSendEnabled(false);
            }
        }
    }, []);

    // Save to localStorage when autoSendEnabled changes
    useEffect(() => {
        localStorage.setItem('autoSendEnabled', JSON.stringify(autoSendEnabled));
    }, [autoSendEnabled]);

    // Handle AutoSend (For Audio to text)
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        setAutoSendEnabled(isChecked);
        localStorage.setItem('autoSendEnabled', JSON.stringify(isChecked));
    };

    async function getFromRecognizer() {
        const tokenResponse = await speechService.getSpeechTokenAsync(
            await AuthHelper.getSKaaSAccessToken(instance, inProgress),
        );
        if (tokenResponse.isSuccess) {
            return speechService.getSpeechRecognizerAsyncWithValidKey(tokenResponse, [fromLang]);
        }
        throw new Error('Failed to get speech token or initialize recognizer for "from" language');
    }

    async function getToRecognizer() {
        const tokenResponse = await speechService.getSpeechTokenAsync(
            await AuthHelper.getSKaaSAccessToken(instance, inProgress),
        );
        if (tokenResponse.isSuccess) {
            return speechService.getSpeechRecognizerAsyncWithValidKey(tokenResponse, [toLang]);
        }
        throw new Error('Failed to get speech token or initialize recognizer for "to" language');
    }

    React.useEffect(() => {
        async function initSpeechRecognizer() {
            try {
                const fromRecognizerInstance = await getFromRecognizer();
                const toRecognizerInstance = await getToRecognizer();
                setFromRecognizer(fromRecognizerInstance);
                setToRecognizer(toRecognizerInstance);
            } catch (error) {
                console.error('Error initializing speech recognizers:', error);
            }
        }

        // Handle the promise returned by initSpeechRecognizer
        initSpeechRecognizer().catch((error) => {
            console.error('Error in initSpeechRecognizer:', error);
        });
    }, [instance, inProgress, fromLang, toLang]);

    const startFromLanguageRecognition = async () => {
        if (!_fromRecognizer) {
            console.error('Recognizer is undefined');
            return;
        }

        // Start recording with the 'from' language recognizer
        await startRecording(_fromRecognizer, true).catch(console.error);
    };

    const startToLanguageRecognition = async () => {
        if (!_toRecognizer) {
            console.error('Recognizer is undefined');
            return;
        }

        // Start recording with the 'to' language recognizer
        await startRecording(_toRecognizer, false).catch(console.error);
    };

    // Play Text-to-Speech
    const handlePlayClick = async (ab: boolean, textPassthru: string) => {
        try {
            // Assuming you have state setters for these
            setIsDisabled(true);

            if (isPaused && speechService.player) {
                speechService.player.resume();
                setIsPaused(false);
                setIsPlaying(true);
            } else {
                let playbackLanguage = 'English';

                // Remove the quotes if they exist
                const fromLanguage = fromLang.replace(/^['"]+|['"]+$/g, '');
                const toLanguage = toLang.replace(/^['"]+|['"]+$/g, '');

                // console.log('Translate from: ' + fromLanguage);
                // console.log('Translate to: ' + toLanguage);
                let genderToPlay = 'Male';

                // Translated version
                if (ab) {
                    // Use translatedText when in "TO" mode
                    //  console.log('========= The translation playback language is going to use: ' + toLanguage);
                    playbackLanguage = toLanguage;
                    if (!textPassthru) {
                        textPassthru = translatedText;
                    }
                    genderToPlay = genderVoiceTB;
                } else {
                    // Use translatedTextInput if it's not empty, otherwise use translatedText
                    //  console.log('========= The translation playback language is going to use: ' + fromLanguage);
                    playbackLanguage = fromLanguage;
                    if (!textPassthru) {
                        textPassthru = inputText;
                    }
                    genderToPlay = genderVoiceTA;
                }

                const response = await speechService.getSpeechTokenAsync(
                    await AuthHelper.getSKaaSAccessToken(instance, inProgress),
                );

                // console.log('TTS the following:');
                // console.log(textPassthru);
                // console.log('Playback language: ' + playbackLanguage);
                // console.log('Gender: ' + genderToPlay);
                // console.log('Pitch: ' + voicePitch);
                // console.log('Speed: ' + selectedVoiceSpeed);

                if (response.isSuccess) {
                    await speechService.convertTextToSpeech(
                        response.token,
                        response.region,
                        textPassthru,
                        genderToPlay,
                        selectedVoiceSpeed,
                        voicePitch,
                        playbackLanguage,
                    );

                    if (speechService.player) {
                        speechService.player.onAudioEnd = () => {
                            setIsPlaying(false);
                            setIsPaused(false);
                            setIsDisabled(false);
                        };

                        setIsPlaying(true);
                    }
                } else {
                    //    console.error('Failed to retrieve speech token and region.');
                    setIsPlaying(false);
                    setIsPaused(false);
                    setIsDisabled(false);
                }
            }
        } catch (error) {
            console.error('Failed to handle play action:', error);
            setIsPlaying(false);
            setIsPaused(false);
            setIsDisabled(false);
        }
    };

    const handlePauseClick = () => {
        if (speechService.player) {
            speechService.player.pause();
            setIsPaused(true);
        }
    };

    const handleStopClick = () => {
        if (speechService.player) {
            speechService.player.pause(); // Assuming pause acts as stop
            setIsPlaying(false);
            setIsPaused(false);
            setIsDisabled(false);
        }
    };

    // Function to clear the "From" textarea
    const clearFromTextarea = () => {
        setInputText('');
    };

    // Function to clear the "To" textarea
    const clearToTextarea = () => {
        setTranslatedText('');
    };

    return (
        <div className="utversal_translation-container">
            <div className="utversal_language-selection">
                <select
                    className="utversal_select"
                    value={fromLang}
                    onChange={(e) => {
                        updateFromLang(e.target.value);
                    }}
                    title="Original Language"
                    aria-label="Original Language"
                >
                    {renderLanguageOptions(languages)}
                </select>

                <button
                    className="utversal_swapbutton"
                    onClick={swapLanguages}
                    title="Swap Languages"
                    aria-label="Swap Languages"
                >
                    <ArrowSwap20Regular />
                </button>
                <select
                    className="utversal_select"
                    value={toLang}
                    onChange={(e) => {
                        updateToLang(e.target.value);
                    }}
                    title="Translated Language"
                    aria-label="Translated Language"
                >
                    {renderLanguageOptions(languages)}
                </select>
            </div>
            <div className="utversal_translation-boxes">
                <div className="utversal_input-area">
                    <div className="utversal_usedlcontainer">
                        <div className="used-languages">
                            {Array.from(usedFromLanguages).map((lang) => (
                                <button
                                    key={lang}
                                    onClick={() => {
                                        handleFromLangTabClick(lang);
                                    }}
                                    className={
                                        lang === fromLang
                                            ? 'utversal_used-language utversal_used-language-active'
                                            : 'utversal_used-language'
                                    }
                                >
                                    {lang}
                                </button>
                            ))}
                        </div>
                        <div>
                            <button
                                title="Clear 'From' Languages"
                                aria-label="Clear 'From' Languages"
                                onClick={() => {
                                    setUsedFromLanguages(new Set([fromLang]));
                                }}
                                className="easybuttons"
                            >
                                <Eraser20Regular />
                            </button>
                        </div>
                    </div>
                    <div className="g_relative">
                        <div className="utversal_clearbroomL">
                            <div>
                                <button
                                    title="Clear Text first user"
                                    aria-label="Clear text for first user"
                                    className="easybuttons"
                                    onClick={clearFromTextarea}
                                >
                                    <Broom20Regular />
                                </button>
                            </div>
                            <button title="Gender toggle for person A" className="easybuttons" onClick={toggleGenderA}>
                                {genderVoiceTA === 'Male' ? '♂' : '♀'}
                            </button>
                        </div>
                        <textarea
                            className={`utversal_textarea ${activeMode === 'TO' ? 'utversal_textactive' : ''}`}
                            placeholder={`Enter text (${fromLang})`}
                            value={inputText}
                            onChange={(e) => {
                                setInputText(e.target.value);
                            }}
                            maxLength={10000} // Set maximum length
                            title={`Enter text (${fromLang})`}
                            aria-label={`Input text (${fromLang})`}
                            readOnly={activeMode === 'FROM'}
                        />
                        <div className="utversal_playerbuttons">
                            {(!isPlaying || isPaused) && (
                                <button
                                    title="Play"
                                    aria-label="Play message"
                                    onClick={() => {
                                        handlePlayClick(false, '').catch((error) => {
                                            console.error('Failed to handle text-to-speech:', error);
                                        });
                                    }}
                                    className={`${isDisabled || isCharLimitExceeded ? 'disabledbutton' : ''} ${
                                        isPaused ? 'easybuttons pausedplay' : 'easybuttons'
                                    }`}
                                    disabled={
                                        isDisabled || isCharLimitExceeded || inputText.length === 0 || isRecording
                                    }
                                >
                                    <Play16Regular />
                                </button>
                            )}

                            {/* Display the Pause button only when playing and not paused */}
                            {isPlaying && !isPaused && (
                                <button
                                    title="Pause"
                                    aria-label="Pause message"
                                    onClick={handlePauseClick}
                                    className="easybuttons"
                                    disabled={isRecording}
                                    //  disabled={conversations.disabled}
                                >
                                    <Pause16Regular />
                                </button>
                            )}

                            {/* Display the Stop button whenever playing, regardless of paused state */}
                            {isPlaying && (
                                <button
                                    title="Stop"
                                    aria-label="Stop message"
                                    onClick={handleStopClick}
                                    className="easybuttons"
                                    disabled={isRecording}
                                    //   disabled={conversations.disabled}
                                >
                                    <Stop16Filled />
                                </button>
                            )}
                        </div>
                        <div className="utversal_charlimit">{`${inputText.length.toLocaleString()} / 10,000 Characters Remaining`}</div>
                        <div className="utversal_modechange">
                            {' '}
                            <button
                                className={`toggle-mode-button ${activeMode === 'TO' ? 'right' : 'left'}`}
                                onClick={handleToggleModeClick}
                                title={`Toggle Conversation Mode (${activeMode})`}
                                aria-label={`Toggle Conversation Mode (${activeMode})`}
                            >
                                <ArrowFlowUpRight24Filled />
                            </button>
                        </div>
                    </div>
                    <button
                        title={isRecording ? 'Stop Recording' : 'Record your voice person A'}
                        aria-label={isRecording ? 'Stop Recording' : 'Record your voice person A'}
                        className="utversal_button"
                        onClick={() => {
                            if (isRecording) {
                                if (_fromRecognizer) {
                                    stopRecording(_fromRecognizer).catch(console.error);
                                }
                            } else {
                                startFromLanguageRecognition().catch(console.error);
                            }
                            setIsRecording(!isRecording);
                        }}
                        disabled={activeMode !== 'TO' || isPlaying}
                    >
                        {isRecording ? 'Stop Recording' : <MicRegular />} Person A
                    </button>

                    {cannotbevoicedfrom && (
                        <div className="utversal_warningText">This language does not support TTS</div>
                    )}
                    <div className="utversal_options">
                        <label>
                            Auto-Playback
                            <input type="checkbox" checked={autoplayback} onChange={handleAutoplaybackChange} />
                        </label>
                        <label>
                            Auto Send Enabled
                            <input type="checkbox" checked={autoSendEnabled} onChange={handleCheckboxChange} />
                        </label>
                    </div>
                </div>
                <div className="utversal_output-area">
                    <div className="utversal_usedlcontainer">
                        <div className="used-languages">
                            {Array.from(usedToLanguages).map((lang) => (
                                <button
                                    key={lang}
                                    onClick={() => {
                                        handleToLangTabClick(lang);
                                    }}
                                    className={
                                        lang === toLang
                                            ? 'utversal_used-language utversal_used-language-active'
                                            : 'utversal_used-language'
                                    }
                                >
                                    {lang}
                                </button>
                            ))}
                        </div>
                        <div>
                            <button
                                title="Clear Languages"
                                aria-label="Clear Languages"
                                onClick={() => {
                                    setUsedToLanguages(new Set([toLang]));
                                }}
                                className="easybuttons"
                            >
                                <Eraser20Regular />
                            </button>
                        </div>
                    </div>
                    <div className="g_relative">
                        <div className="utversal_clearbroomR">
                            <div>
                                <button
                                    title="Clear Text"
                                    aria-label="Clear text for second user"
                                    className="easybuttons"
                                    onClick={clearToTextarea}
                                >
                                    <Broom20Regular />
                                </button>
                            </div>
                            <div>
                                <button
                                    title="Gender toggle for person B"
                                    className="easybuttons"
                                    onClick={toggleGenderB}
                                >
                                    {genderVoiceTB === 'Male' ? '♂' : '♀'}
                                </button>
                            </div>
                        </div>
                        <textarea
                            className={`utversal_textarea ${activeMode === 'FROM' ? 'utversal_textactive' : ''}`}
                            placeholder={`Translation (${toLang})`}
                            value={translatedText}
                            readOnly={activeMode === 'TO'}
                            onChange={(event) => {
                                setTranslatedText(event.target.value);
                            }}
                            title={`Translation (${toLang})`}
                            aria-label={`Translated text (${toLang})`}
                            maxLength={10000} // Set maximum length
                        />
                        <div className="utversal_playerbuttons">
                            {(!isPlaying || isPaused) && (
                                <button
                                    title="Play"
                                    aria-label="Play message"
                                    onClick={() => {
                                        handlePlayClick(true, '').catch((error) => {
                                            console.error('Failed to handle text-to-speech:', error);
                                        });
                                    }}
                                    className={`${isDisabled ? 'disabledbutton' : ''} ${
                                        isPaused ? 'easybuttons pausedplay' : 'easybuttons'
                                    }`}
                                    disabled={isDisabled || translatedText.length === 0 || isRecording}
                                >
                                    <Play16Regular />
                                </button>
                            )}

                            {/* Display the Pause button only when playing and not paused */}
                            {isPlaying && !isPaused && (
                                <button
                                    title="Pause"
                                    aria-label="Pause message"
                                    onClick={handlePauseClick}
                                    className="easybuttons"
                                    disabled={isRecording}
                                    //  disabled={conversations.disabled}
                                >
                                    <Pause16Regular />
                                </button>
                            )}

                            {/* Display the Stop button whenever playing, regardless of paused state */}
                            {isPlaying && (
                                <button
                                    title="Stop"
                                    aria-label="Stop message"
                                    onClick={handleStopClick}
                                    className="easybuttons"
                                    disabled={isRecording}
                                    //   disabled={conversations.disabled}
                                >
                                    <Stop16Filled />
                                </button>
                            )}
                        </div>
                        <div className="utversal_charlimit">{`${translatedText.length.toLocaleString()} / 10,000 Characters Remaining`}</div>
                    </div>
                    <button
                        title={isRecording ? 'Stop Recording' : 'Record your voice person B'}
                        aria-label={isRecording ? 'Stop Recording' : 'Record your voice person B'}
                        className="utversal_button"
                        onClick={() => {
                            if (isRecording) {
                                if (_toRecognizer) {
                                    stopRecording(_toRecognizer).catch(console.error);
                                }
                            } else {
                                startToLanguageRecognition().catch(console.error);
                            }
                            setIsRecording(!isRecording);
                        }}
                        disabled={activeMode !== 'FROM' || isPlaying}
                    >
                        {isRecording ? 'Stop Recording' : <MicRegular />} Person B
                    </button>
                    {cannotbevoicedto && <div className="utversal_warningText">This language does not support TTS</div>}
                    <div className="utversal_options">
                        <label>
                            Enable Conversational Mode
                            <input
                                type="checkbox"
                                checked={conversationalMode}
                                onChange={handleConversationalModeChange}
                            />
                        </label>
                    </div>
                </div>
            </div>

            <div className="utversal_controls">
                <button
                    className="utversal_button_b"
                    onClick={handleTranslateClick}
                    disabled={
                        isTranslating ||
                        (activeMode === 'TO' && (!inputText.trim() || inputText.length > 10000)) ||
                        (activeMode === 'FROM' && (!translatedText.trim() || translatedText.length > 10000))
                    }
                    title="Translate"
                    aria-label="Translate"
                >
                    Translate
                </button>
            </div>
            <TranslationHistory history={history} />
        </div>
    );
};
export default TranslateTab;
