import { ReactNode, createContext, useState } from 'react';

interface AppContextType {
    currentSection: string;
    setCurrentSection: (section: string) => void;
}

const AppContext = createContext<AppContextType>({
    currentSection: '',
    setCurrentSection: () => {},
});

interface AppContextProviderProps {
    children: ReactNode;
}

export const AppContextProvider = ({ children }: AppContextProviderProps) => {
    const [currentSection, setCurrentSection] = useState<string>('home');

    return <AppContext.Provider value={{ currentSection, setCurrentSection }}>{children}</AppContext.Provider>;
};

export default AppContext;
