import { makeStyles, mergeClasses, shorthands, Text, tokens } from '@fluentui/react-components';
import { ShieldTask16Regular } from '@fluentui/react-icons';
import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { FeatureKeys } from '../../../redux/features/app/AppState';
import { setSelectedConversation } from '../../../redux/features/conversations/conversationsSlice';
import { SharedStyles } from '../../../styles';
import { timestampToDateString } from '../../utils/TextUtils';
import { EditChatName } from '../shared/EditChatName';
import { ListItemActions } from './ListItemActions';

const useClasses = makeStyles({
    root: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        width: 'calc(100% - 10px)',
        justifyContent: 'center',
        ...shorthands.margin('5px'),
        cursor: 'pointer',
        ...shorthands.padding('10px'),
        ...shorthands.borderRadius('8px'),
        borderBottomWidth: '1px',
        borderBottomColor: '#e5e5e5',
        borderBottomStyle: 'inset',
    },
    avatar: {
        flexShrink: 0,
        width: '32px',
    },
    body: {
        minWidth: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: tokens.spacingHorizontalXS,
        alignSelf: 'center',
        fontFamily: 'inherit',
    },
    header: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontFamily: 'inherit',
    },
    title: {
        ...SharedStyles.overflowEllipsis,
        fontSize: tokens.fontSizeBase300,
        color: tokens.colorNeutralForeground1,
    },
    timestamp: {
        flexShrink: 0,
        marginLeft: tokens.spacingHorizontalM,
        fontSize: tokens.fontSizeBase200,
        color: tokens.colorNeutralForeground4,
        lineHeight: tokens.lineHeightBase200,
    },
    previewText: {
        ...SharedStyles.overflowEllipsis,
        display: 'block',
        lineHeight: tokens.lineHeightBase100,
        color: tokens.colorNeutralForeground2,
    },
    popoverSurface: {
        display: 'none',
    },
    selected: {
        backgroundColor: tokens.colorNeutralBackground1,
    },
    protectedIcon: {
        color: tokens.colorPaletteLightGreenBorder1,
        verticalAlign: 'text-bottom',
        marginLeft: tokens.spacingHorizontalXS,
    },
});

interface IChatListItemProps {
    id: string;
    header: string;
    timestamp: number;
    preview: string;
    itsMe: boolean;
    botProfilePicture: string;
    isSelected: boolean;
}

export const ChatListItem: FC<IChatListItemProps> = ({
    id,
    header,
    timestamp,
    preview,
    itsMe,
    // botProfilePicture,
    isSelected,
}) => {
    const classes = useClasses();
    const dispatch = useAppDispatch();
    const { features } = useAppSelector((state: RootState) => state.app);

    const showPreview = !features[FeatureKeys.SimplifiedExperience].enabled && preview;
    const showActions = features[FeatureKeys.SimplifiedExperience].enabled && isSelected;

    const [editingTitle, setEditingTitle] = useState(false);

    const onClick = (_ev: any) => {
        dispatch(setSelectedConversation(id));
    };

    const [currentFont, setCurrentFont] = useState(localStorage.getItem('selectedFont') ?? 'fontMontserrat');

    // Effect to update the font based on localStorage changes
    useEffect(() => {
        const updateFont = (event: CustomEvent<string>) => {
            setCurrentFont(event.detail);
        };

        window.addEventListener('fontChange', updateFont as EventListener);

        // Initialize the font
        const storedFont = localStorage.getItem('selectedFont') ?? 'fontMontserrat';
        setCurrentFont(storedFont);

        return () => {
            window.removeEventListener('fontChange', updateFont as EventListener);
        };
    }, []);

    const time = timestampToDateString(timestamp);
    return (
        <div
            className={`${mergeClasses(classes.root)} _cndsiwer2 ${isSelected ? 'hitemsel' : ''}`}
            onMouseEnter={() => {
                //  setIsHovered(true);
            }}
            onMouseLeave={() => {
                if (!isSelected) {
                    //        setIsHovered(false);
                }
            }}
            onClick={onClick}
            title={`Chat: ${header}`}
            aria-label={`Chat list item: ${header}`}
        >
            {/* <Persona
                avatar={{ image: { src: botProfilePicture } }}
                presence={!features[FeatureKeys.SimplifiedExperience].enabled ? { status: 'available' } : undefined}
            /> */}
            {editingTitle ? (
                <EditChatName
                    name={header}
                    chatId={id}
                    exitEdits={() => {
                        setEditingTitle(false);
                    }}
                />
            ) : (
                <>
                    <div className={classes.body}>
                        <div className={classes.header}>
                            <Text className={classes.title} title={header}>
                                <span className={currentFont}>{header}</span>
                                {features[FeatureKeys.AzureContentSafety].enabled && (
                                    <ShieldTask16Regular className={classes.protectedIcon} />
                                )}
                            </Text>
                            {!features[FeatureKeys.SimplifiedExperience].enabled && (
                                <Text className={classes.timestamp} size={300}>
                                    <span className={currentFont}>{time}</span>
                                </Text>
                            )}
                            <Text className={classes.timestamp} size={300}>
                                <span className={currentFont}>{time}</span>
                            </Text>
                        </div>
                        {showPreview && (
                            <>
                                {
                                    <Text id={`message-preview-${id}`} size={200} className={classes.previewText}>
                                        {preview}
                                    </Text>
                                }
                            </>
                        )}
                    </div>
                    {itsMe && showActions && (
                        <ListItemActions
                            chatId={id}
                            onEditTitleClick={() => {
                                setEditingTitle(true);
                            }}
                        />
                    )}
                </>
            )}
        </div>
    );
};
