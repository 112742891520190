import { Text, makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components';
import { CheckmarkCircle24Regular, DismissCircle24Regular, Info24Regular } from '@fluentui/react-icons';
import { useCallback, useEffect, useState } from 'react';
import { useChat } from '../../../libs/hooks/useChat';
import { IChatMessage } from '../../../libs/models/ChatMessage';
import { PlanState, ProposedPlan } from '../../../libs/models/Plan';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { updateMessageProperty } from '../../../redux/features/conversations/conversationsSlice';

export const usePlanViewClasses = makeStyles({
    container: {
        ...shorthands.gap(tokens.spacingVerticalM),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'baseline',
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: tokens.spacingVerticalM,
        marginBottom: tokens.spacingVerticalM,
        ...shorthands.gap(tokens.spacingHorizontalL),
    },
    status: {
        ...shorthands.gap(tokens.spacingHorizontalMNudge),
    },
    text: {
        alignSelf: 'center',
    },
});

interface PlanViewerProps {
    message: IChatMessage;
    messageIndex: number;
}

export const PlanViewer: React.FC<PlanViewerProps> = ({ message, messageIndex }) => {
    const classes = usePlanViewClasses();
    const dispatch = useAppDispatch();
    const { selectedId } = useAppSelector((state: RootState) => state.conversations);
    const chat = useChat();

    // Track original plan from user message
    const parsedContent = JSON.parse(message.content) as ProposedPlan;
    const originalPlan = parsedContent.proposedPlan;
    const planState =
        parsedContent.state === PlanState.Derived ? PlanState.Derived : message.planState ?? parsedContent.state;
    const [plan] = useState(originalPlan);

    const onPlanAction = useCallback(async () => {
        const updatedPlan = JSON.stringify({
            ...parsedContent,
            proposedPlan: plan,
            state: PlanState.Approved,
            generatedPlanMessageId: message.id,
        });

        // Update bot message with new plan state
        dispatch(
            updateMessageProperty({
                messageIdOrIndex: messageIndex,
                chatId: selectedId,
                property: 'planState',
                value: PlanState.Approved,
                updatedContent: updatedPlan,
                frontLoad: true,
            }),
        );

        await chat.processPlan(selectedId, PlanState.Approved, updatedPlan);
    }, [chat, dispatch, message.id, messageIndex, parsedContent, plan, selectedId]);

    useEffect(() => {
        // Automatically approve the plan when it is generated
        if (planState === PlanState.PlanApprovalRequired) {
            void onPlanAction();
        }
    }, [planState, onPlanAction, plan]);

    return (
        <div className={classes.container}>
            {(planState === PlanState.Approved || planState === PlanState.Derived) && (
                <div className={mergeClasses(classes.buttons, classes.status)}>
                    <CheckmarkCircle24Regular />
                    <Text className={classes.text}> Running Plugin Event...</Text>
                </div>
            )}
            {planState === PlanState.Rejected && (
                <div className={mergeClasses(classes.buttons, classes.status)}>
                    <DismissCircle24Regular />
                    <Text className={classes.text}> Plugin Event Cancelled</Text>
                </div>
            )}
            {(planState === PlanState.NoOp || planState === PlanState.Disabled) && (
                <div className={mergeClasses(classes.buttons, classes.status)}>
                    <Info24Regular />
                    <Text className={classes.text}>
                        {planState === PlanState.NoOp
                            ? 'Your app state has changed since this plan was generated, making it unreliable for the planner. Please request a fresh plan to avoid potential conflicts.'
                            : 'Only the person who prompted this plan can take action on it.'}
                    </Text>
                </div>
            )}
        </div>
    );
};
