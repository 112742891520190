import { useMsal } from '@azure/msal-react';
import {
    AvatarProps,
    Persona,
    Text,
    // ToggleButton,
    makeStyles,
    mergeClasses,
    shorthands,
} from '@fluentui/react-components';
import {
    Chat24Regular,
    // ChevronDown20Regular,
    // ChevronUp20Regular,
    ClipboardRegular,
    Copy20Filled,
    Copy20Regular,
    FontIncrease24Regular,
    Highlight24Regular,
    NumberSymbol20Filled,
    NumberSymbol20Regular,
    Pause16Regular,
    Play16Regular,
    SplitHorizontal20Regular,
    Stop16Filled,
    TextUnderline24Filled,
    TextWrap20Filled,
    TextWrap20Regular,
    ThumbDislikeFilled,
    ThumbLikeFilled,
    Translate16Filled,
} from '@fluentui/react-icons';
import 'katex/dist/katex.min.css';
import 'prism-themes/themes/prism-vsc-dark-plus.css';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { BlockMath, InlineMath } from 'react-katex';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import remarkGfm from 'remark-gfm';
import { AuthHelper } from '../../../libs/auth/AuthHelper';
import { useChat } from '../../../libs/hooks/useChat';
import { AlertType } from '../../../libs/models/AlertType';
import { AuthorRoles, ChatMessageType, IChatMessage, UserFeedback } from '../../../libs/models/ChatMessage';
import { SpeechService } from '../../../libs/services/SpeechService';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { DefaultChatUser, FeatureKeys } from '../../../redux/features/app/AppState';
import { addAlert } from '../../../redux/features/app/appSlice';
import { Breakpoints, customTokens } from '../../../styles';
import { timestampToDateString } from '../../utils/TextUtils';
import { PlanViewer } from '../plan-viewer/PlanViewer';
import { TypingIndicator } from '../typing-indicator/TypingIndicator';
import { HandleClickFunction, HandleHoverFunction } from './ChatHistory';
import { ChatHistoryDocumentContent } from './ChatHistoryDocumentContent';
// import { CitationCards } from './CitationCards';
import { CodeBlock } from './CodeBlock';
import { UserFeedbackActions } from './UserFeedbackActions';

const useClasses = makeStyles({
    defaultFontStyle: {},
    root: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '100%',
        minWidth: '100%',
        width: '100%',
        marginTop: '10px',
        marginBottom: '10px',
        ...shorthands.borderRadius(customTokens.borderRadiusMedium),
        ...Breakpoints.small({
            maxWidth: '100%',
        }),
        ...shorthands.gap(customTokens.spacingHorizontalXS),
    },
    debug: {
        position: 'absolute',
        top: '-4px',
        right: '-4px',
    },
    alignEnd: {
        alignSelf: 'flex-end',
    },
    persona: {
        paddingTop: customTokens.spacingVerticalS,
    },
    item: {
        width: '100%',
        backgroundColor: customTokens.colorNeutralBackground1,
        ...shorthands.borderRadius(customTokens.borderRadiusMedium),
        ...shorthands.padding(customTokens.spacingVerticalS, customTokens.spacingHorizontalL),
    },
    me: {
        backgroundColor: '#f6f6f6',
    },
    time: {
        color: customTokens.colorNeutralForeground3,
        fontSize: customTokens.fontSizeBase200,
        fontWeight: 400,
    },
    header: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.gap(customTokens.spacingHorizontalL),
    },
    canvas: {
        width: '100%',
        textAlign: 'center',
    },
    image: {
        maxWidth: '250px',
    },
    blur: {
        filter: 'blur(5px)',
    },
    copiedIndicator: {
        color: '#313e48',
        fontSize: '0.8rem',
        fontWeight: 'bold',
        zIndex: '10',
    },
    clipboard: {
        cursor: 'pointer',
        backgroundColor: 'transparent',
        opacity: 0.7,
        ':hover': {
            // hover state
            opacity: 1,
            color: '#ff8200',
        },
    },
    playerActive: {
        opacity: 1,
    },
    clipboardTranslate: {
        cursor: 'pointer',
        opacity: 0.7,
        backgroundColor: 'transparent',
        ':hover': {
            // hover state
            opacity: 1,
            color: '#ff8200',
        },
    },
    controls: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: customTokens.spacingVerticalS,
        marginBottom: customTokens.spacingVerticalS,
        ...shorthands.gap(customTokens.spacingHorizontalL),
    },
    citationButton: {
        marginRight: 'auto',
    },
    rlhf: {
        marginLeft: 'auto',
    },
});

interface ChatHistoryItemProps {
    message: IChatMessage;
    messageIndex: number;
    userSettings: UserSettings;
    handleHover: HandleHoverFunction;
    handleClick: HandleClickFunction;
    onOpenTab: (codeBlock: CodeBlock) => void;
}

interface UserSettings {
    voiceGender: string;
    translateTo: string;
    ignoreCodeVoice: string;
    voiceSpeed: string;
    voicePitch: string;
    forceSpeechLang: string;
    wordSpacing: string;
    lineHeight: number;
    fontSize: string;
}

interface AudioControlButtonsProps {
    message: string;
    isPlaying: boolean;
    isPaused: boolean;
    selectedId: string;
    isNotTranslateContainer: boolean;
    handlePlayClick: (content: string) => Promise<void>;
    handlePauseClick: () => void;
    handleStopClick: () => void;
    handleTranslateClick: (content: string) => Promise<void>;
    handleCopyClick: (flag: boolean) => void;
}

interface BubblePosition {
    x: number;
    y: number;
}

interface CustomParagraphProps extends HTMLAttributes<HTMLParagraphElement> {
    children?: React.ReactNode;
    [key: string]: any; // Allows any additional props
}
const CustomParagraph: React.FC<CustomParagraphProps> = ({ children, ...props }) => {
    const childArray = React.Children.toArray(children).filter(React.isValidElement);

    const onlyInlineElements = childArray.every((child) => {
        // Adjust your condition here as needed
        return (
            'className' in (child.props as { className?: string }) &&
            (child.props as { className?: string }).className === 'inlinecode'
        );

        // Add more conditions for other types of inline elements if necessary
    });

    if (onlyInlineElements) {
        return <>{children}</>;
    }

    return <p {...props}>{children}</p>;
};

export const ChatHistoryItem: React.FC<ChatHistoryItemProps> = ({
    message,
    messageIndex,
    userSettings,
    handleHover,
    handleClick,
    onOpenTab,
}) => {
    //console.log(userSettings);
    const { instance, inProgress } = useMsal();
    const classes = useClasses();
    const chat = useChat();
    const dispatch = useAppDispatch();

    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const { activeUserInfo, features } = useAppSelector((state: RootState) => state.app);
    // const [showCitationCards, setShowCitationCards] = useState(false);
    // console.log('<><><><><><> Message DATA <><><><><><><>');
    // console.log(message);

    const isDefaultUser = message.userId === DefaultChatUser.id;
    const isMe = isDefaultUser || (message.authorRole === AuthorRoles.User && message.userId === activeUserInfo?.id);
    const isBot = message.authorRole === AuthorRoles.Bot;
    const user = isDefaultUser
        ? DefaultChatUser
        : chat.getChatUserById(message.userName, selectedId, conversations[selectedId].users);
    const fullName = user?.fullName ?? message.userName;

    const avatar: AvatarProps = isBot
        ? { image: { src: conversations[selectedId].botProfilePicture } }
        : isDefaultUser
          ? { idForColor: selectedId, color: 'colorful' }
          : { name: fullName, color: 'colorful' };

    const [isPlaying, setIsPlaying] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    function getPrismLanguage(language: string): string {
        const languageMap: Record<string, string> = {
            POWERSHELL: 'powershell', // Assuming PrismJS recognizes 'powershell'
            DOS: 'batch', // Mapping 'DOS' to 'batch' which is commonly used for DOS batch files
            TERMINAL: 'shell', // 'TERMINAL' might be mapped to 'shell' or another similar language
            shell: 'bash', // Example of mapping 'shell' to 'bash'
            sh: 'bash', // Example of mapping 'sh' to 'bash' as well
            // Add more mappings as needed
        };

        return languageMap[language.toUpperCase()] || language; // Use .toUpperCase() to ensure case-insensitive matching
    }

    const [translatedText, setTranslatedText] = useState('');

    const handleTranslateClick = async (text: string) => {
        try {
            // Pre-translation: Encode code blocks, inline code, and footnotes into placeholders
            const codeBlockRegex =
                /(```[\s\S]*?```|<span class='inlinecode'>(.*?)<\/span>|https?:\/\/[^\s]+|\[\^([^\]]+?)\^\])/g;

            const placeholders: string[] = []; // Explicitly type the array
            const textWithPlaceholders = text.replace(codeBlockRegex, (match, _, __, p3: string) => {
                const placeholder = `9d6CODE_3LBLOCK93_0606PLACEHOLDER7756_${placeholders.length}`;
                placeholders.push(p3 ? p3 : match); // If the match is a footnote, only store the number
                return placeholder;
            });

            // console.log('Placeholders:', placeholders);
            // console.log('Text with placeholders:', textWithPlaceholders);

            // Initialize the speech service
            const speechService = new SpeechService();

            // Translate the text with placeholders
            const translatedTextWithPlaceholders = await speechService.translateText(
                textWithPlaceholders,
                userSettings.translateTo,
            );

            // console.log('Translated text with placeholders:', translatedTextWithPlaceholders); // Log the translated text with placeholders

            // Post-translation: Decode placeholders back into code blocks, inline code, and footnotes
            let translatedText = translatedTextWithPlaceholders;
            placeholders.forEach((original, index) => {
                // Use a RegExp to replace placeholders in the translated text
                const placeholder = `9d6CODE_3LBLOCK93_0606PLACEHOLDER7756_${index}`;
                const replacement =
                    original.startsWith('[^') && original.endsWith('^]') ? `[^${original.slice(2, -2)}^]` : original; // If the original was a footnote number, add the footnote markers back
                translatedText = translatedText.replace(new RegExp(placeholder, 'g'), replacement);
            });

            // console.log('Translated text:', translatedText); // Log the translated text

            // Set the translated text with code blocks, inline code, and footnotes restored
            setTranslatedText(translatedText);
        } catch (error) {
            let errorMessage = 'Failed to translate text';
            if (error instanceof Error) {
                errorMessage += ': ' + error.message;
            }

            dispatch(
                addAlert({
                    message: errorMessage,
                    type: AlertType.Info,
                }),
            );
        }
    };

    useEffect(() => {
        // If the audio is not playing or is paused, enable the button
        if (!isPlaying || isPaused) {
            setIsDisabled(false);
        }
    }, [isPlaying, isPaused]);

    const speechService = SpeechService.getInstance();

    const handlePlayClick = async (text: string) => {
        try {
            // Disable the play button
            setIsDisabled(true);

            // Function to remove Markdown syntax with TypeScript typing
            const removeMarkdown = (markdownText: string): string => {
                // Regular expression to match Markdown syntax for headings, inline code, and code blocks
                const regex = /(```[\s\S]*?```|`[^`]*`|\#{1,6}\s)/g;
                return markdownText.replace(regex, '');
            };

            // Remove Markdown syntax
            const cleanText: string = removeMarkdown(text);

            // Check if the audio is paused and resume it
            if (isPaused && speechService.player) {
                speechService.player.resume();
                setIsPaused(false);
                setIsPlaying(true);
            } else {
                // Remove code blocks if ignoreCodeVoice is set to "1"
                const modifiedText: string =
                    userSettings.ignoreCodeVoice === 'True' ? cleanText.replace(/```[\s\S]*?```/g, '') : cleanText;

                // Start the speech synthesis process
                const response = await speechService.getSpeechTokenAsync(
                    await AuthHelper.getSKaaSAccessToken(instance, inProgress),
                );

                if (response.isSuccess) {
                    await speechService.convertTextToSpeech(
                        response.token,
                        response.region,
                        modifiedText,
                        userSettings.voiceGender,
                        userSettings.voiceSpeed,
                        userSettings.voicePitch,
                        userSettings.forceSpeechLang,
                    );

                    if (speechService.player) {
                        speechService.player.onAudioEnd = () => {
                            setIsPlaying(false);
                            setIsPaused(false);

                            // Re-enable the play button
                            setIsDisabled(false);
                        };

                        setIsPlaying(true);
                    }
                } else {
                    console.error('Failed to retrieve speech token and region.');
                }
            }
        } catch (error) {
            console.error('Failed to handle play action:', error);
        }
    };

    const handlePauseClick = () => {
        if (speechService.player) {
            speechService.player.pause();
            setIsPaused(true);
        }
    };

    const handleStopClick = () => {
        if (speechService.player) {
            // Assuming there's a method to stop the speech
            speechService.player.pause(); // or an equivalent method
            setIsPlaying(false);
            setIsPaused(false);
        }
    };

    type ContentSegment =
        | string
        | { code: string; language?: string; inline?: boolean }
        | { latex: string; type: 'inline' | 'block' };

    type Segment = string | ContentSegment;

    function AudioControlButtons({
        message,
        isPlaying,
        isPaused,
        // selectedId,
        isNotTranslateContainer,
        handlePlayClick,
        handlePauseClick,
        handleStopClick,
        handleTranslateClick,
        handleCopyClick,
    }: AudioControlButtonsProps) {
        return (
            <div className="messageCC">
                {/* Display the Play button only when not playing or when paused */}
                {(!isPlaying || isPaused) && (
                    <button
                        title="Play"
                        aria-label="Play message"
                        onClick={() => {
                            handlePlayClick(message).catch((error) => {
                                console.error('Failed to handle text-to-speech:', error);
                            });
                        }}
                        className={`${isDisabled ? 'disabledbutton' : ''} ${
                            isPaused ? 'easybuttons pausedplay' : 'easybuttons'
                        }`}
                        disabled={isDisabled}
                    >
                        <Play16Regular />
                    </button>
                )}

                {/* Display the Pause button only when playing and not paused */}
                {isPlaying && !isPaused && (
                    <button
                        title="Pause"
                        aria-label="Pause message"
                        onClick={handlePauseClick}
                        className="easybuttons"
                        //  disabled={conversations.disabled}
                    >
                        <Pause16Regular />
                    </button>
                )}

                {/* Display the Stop button whenever playing, regardless of paused state */}
                {isPlaying && (
                    <button
                        title="Stop"
                        aria-label="Stop message"
                        onClick={handleStopClick}
                        className="easybuttons"
                        //   disabled={conversations.disabled}
                    >
                        <Stop16Filled />
                    </button>
                )}

                {/* Other buttons */}
                <button
                    title="Translate"
                    aria-label="Translate message"
                    className="easybuttons"
                    onClick={() => {
                        handleTranslateClick(message).catch((error) => {
                            console.error('Failed to handle translation:', error);
                        });
                    }}
                    // disabled={conversations.disabled}
                >
                    <Translate16Filled />
                </button>
                <button
                    title="Copy to clipboard"
                    aria-label="Copy to clipboard"
                    className={`clipboardTranslate easybuttons`}
                    onClick={() => {
                        handleCopyClick(isNotTranslateContainer);
                    }}
                >
                    <ClipboardRegular />
                </button>
            </div>
        );
    }

    const codeBlockRegex = /```(\w+)?\s*([\s\S]*?)```|`([^`]+)`/g;
    // const latexRegex = /\$\$((?:(?!\$\$).)+)\$\$/g;
    const latexRegex = /\$\$?([\s\S]+?)\$\$?/g;

    /* Normal Content */
    // TODO: Create function so not to duplicate.

    function processMessage(messageContent: string): ContentSegment[] {
        const splitContent: ContentSegment[] = [];
        let lastIndex = 0;
        let match;

        while ((match = codeBlockRegex.exec(messageContent)) !== null) {
            const textContent = messageContent.slice(lastIndex, match.index);
            if (textContent) {
                // Process any LaTeX in the text content before the code block
                splitContent.push(...processLatex(textContent));
            }

            if (match[2]) {
                const language = match[1] || 'plaintext';
                const code = match[2].trim();
                splitContent.push({ code, language });
            } else if (match[3]) {
                const code = match[3].trim();
                splitContent.push({ code, inline: true });
            }

            lastIndex = codeBlockRegex.lastIndex;
        }

        const remainingTextContent = messageContent.slice(lastIndex);
        if (remainingTextContent) {
            splitContent.push(...processLatex(remainingTextContent));
        }

        return splitContent;
    }

    // function extractCodeBlocks(text: string): { placeholderText: string; codeBlocks: string[] } {
    //     const codeBlocks: string[] = [];
    //     const placeholderText = text.replace(codeBlockRegex, (match) => {
    //         const index = codeBlocks.length;
    //         codeBlocks.push(match); // Store the entire matched code block
    //         return `{{CODE_BLOCK_${index}}}`; // Use the captured index for the placeholder
    //     });
    //     return { placeholderText, codeBlocks };
    // }

    function processLatex(text: string): Segment[] {
        const latexContent: Segment[] = [];
        let lastIndex = 0;
        let match;

        while ((match = latexRegex.exec(text)) !== null) {
            const normalText = text.slice(lastIndex, match.index);
            if (normalText) latexContent.push(normalText); // Push text between LaTeX expressions

            const latex = match[1].trim();
            const isBlock = match[0].startsWith('$$') && match[0].endsWith('$$');
            latexContent.push({ latex, type: isBlock ? 'block' : 'inline' }); // Mark LaTeX for rendering

            lastIndex = latexRegex.lastIndex;
        }

        const remainingText = text.slice(lastIndex);
        if (remainingText) latexContent.push(remainingText); // Push any remaining text after the last LaTeX expression

        return latexContent;
    }
    // function reinsertCodeBlocks(contentSegments: Segment[], codeBlocks: string[]): Segment[] {
    //     return contentSegments.map((segment) => {
    //         if (typeof segment === 'string') {
    //             // Replace placeholders with the original code blocks
    //             return segment.replace(/{{CODE_BLOCK_(\d+)}}/g, (_, index) => codeBlocks[Number(index)]);
    //         }
    //         return segment; // LaTeX segments are returned as is
    //     });
    // }
    // function processContent(content: string): Segment[] {
    //     const { placeholderText, codeBlocks } = extractCodeBlocks(content);
    //     const latexContent = processLatex(placeholderText);
    //     const finalContent = reinsertCodeBlocks(latexContent, codeBlocks);

    //     // Now `finalContent` is an array of Segment, ready for rendering.
    //     return finalContent;
    // }

    // function determineLatexType(latex: string): 'inline' | 'block' {
    //     // Implement your logic or heuristic to determine the type
    //     // Example (very simplistic):
    //     return latex.length > 50 ? 'block' : 'inline'; // Or use other criteria
    // }

    const [showShowRLHFMessage] = useState(false);
    //<Button onClick={() => setShowShowRLHFMessage(true)}>Show RLHF Message</Button>

    const [isCopiedCode, setIsCopiedCode] = useState(false);

    const handleCopyClickCode = (code: string) => {
        new Promise((resolve, reject) => {
            navigator.clipboard.writeText(code).then(resolve).catch(reject);
        })
            .then(() => {
                setIsCopiedCode(true);
                // Reset the button text after 3 seconds
                setTimeout(() => {
                    setIsCopiedCode(false);
                }, 3000);
            })
            .catch((err) => {
                console.error('Failed to copy text: ', err);
            });
    };

    // Line Numbers
    const [showLineNumbers, setShowLineNumbers] = useState(true);
    const toggleLineNumbers = () => {
        setShowLineNumbers(!showLineNumbers);
    };

    // Word Wrap
    const [textWrapSetting, setTextWrapSetting] = useState(true);
    const toggleTextWrap = () => {
        setTextWrapSetting(!textWrapSetting);
    };

    // Bubble Highlight
    const [showBubble, setShowBubble] = useState<boolean>(false);
    const [bubblePosition, setBubblePosition] = useState<BubblePosition>({ x: 0, y: 0 });
    const [selectedText, setSelectedText] = useState<Selection | null>(null);

    const hideBubble = () => {
        // Delay hiding the bubble to allow other click events to process
        setTimeout(() => {
            setShowBubble(false);
        }, 5); // 10 milliseconds delay
    };

    const updateSelection = (e: MouseEvent | KeyboardEvent) => {
        // Check if the event target is part of the bubble UI
        if ((e.target as HTMLElement).closest('.bubble')) {
            hideBubble(); // Hide the bubble if an element inside it is clicked
        }

        const selection = window.getSelection();
        if (selection && isValidHighlight(selection)) {
            const text = selection.toString().trim();
            if (text) {
                setShowBubble(true);
                if (e instanceof MouseEvent) {
                    setBubblePosition({ x: e.clientX, y: e.clientY });
                }
                setSelectedText(selection);
            } else {
                setShowBubble(false);
            }
        }
    };

    useEffect(() => {
        const mouseUpHandler = (e: MouseEvent) => {
            updateSelection(e);
        };
        // const keyUpHandler = (e: KeyboardEvent) => {
        //     updateSelection(e);
        // };

        document.addEventListener('mouseup', mouseUpHandler);
        // document.addEventListener('keyup', keyUpHandler);

        return () => {
            document.removeEventListener('mouseup', mouseUpHandler);
            // document.removeEventListener('keyup', keyUpHandler);
        };
    }, []);

    const excludedClasses = [
        'highlighter-pink',
        'highlighter-blue',
        'highlighter-yellow',
        'highlighter-black',
        'largerFont',
        'underlineThick',
    ];
    const allowedTags = [
        'strong',
        'b',
        'u',
        'del',
        'small',
        'ins',
        'mark',
        'sub',
        'sup',
        'em',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'h7',
    ];

    const isValidHighlight = (selection: Selection): boolean => {
        if (!selection.rangeCount) return false;

        const range = selection.getRangeAt(0);

        const walker = document.createTreeWalker(range.commonAncestorContainer, NodeFilter.SHOW_ELEMENT, {
            acceptNode: (node) => {
                if (node instanceof HTMLElement) {
                    return NodeFilter.FILTER_ACCEPT;
                }
                return NodeFilter.FILTER_SKIP;
            },
        });

        let node: Node | null = walker.nextNode();
        while (node) {
            // Explicitly assert that node is of type HTMLElement
            const htmlNode = node as HTMLElement;

            if (range.intersectsNode(node)) {
                if (
                    excludedClasses.some((className) => htmlNode.classList.contains(className)) ||
                    !allowedTags.includes(htmlNode.tagName)
                ) {
                    return false;
                }
            }
            node = walker.nextNode();
        }
        return true;
    };

    const applyStyleToRange = (range: Range, styleCallback: (element: HTMLElement) => void) => {
        const tempRange = range.cloneRange();

        // Function to expand the range to include full words
        const expandRangeToFullWords = (range: Range) => {
            const startNodeText = range.startContainer.textContent ?? '';
            const endNodeText = range.endContainer.textContent ?? '';

            // Expand the start of the range to the beginning of the word
            let start = range.startOffset;
            while (start > 0 && startNodeText[start - 1] !== ' ') {
                start--;
            }
            range.setStart(range.startContainer, start);

            // Expand the end of the range to the end of the word
            let end = range.endOffset;
            while (
                end < endNodeText.length &&
                endNodeText[end] !== ' ' &&
                endNodeText[end] !== '.' &&
                endNodeText[end] !== ','
            ) {
                end++;
            }
            range.setEnd(range.endContainer, end);
        };

        // Function to check if the range contains only allowed elements
        const containsOnlyAllowedElements = (range: Range): boolean => {
            const walker = document.createTreeWalker(range.commonAncestorContainer, NodeFilter.SHOW_ELEMENT, {
                acceptNode: (node) => {
                    if (node instanceof HTMLElement) {
                        if (excludedClasses.some((className) => node.classList.contains(className))) {
                            return NodeFilter.FILTER_REJECT;
                        }
                        if (allowedTags.includes(node.tagName)) {
                            return NodeFilter.FILTER_ACCEPT;
                        }
                        return NodeFilter.FILTER_REJECT;
                    }
                    return NodeFilter.FILTER_ACCEPT;
                },
            });

            let node = walker.nextNode();
            while (node) {
                if (range.intersectsNode(node)) {
                    return false; // Found a disallowed element
                }
                node = walker.nextNode();
            }
            return true;
        };

        // Expand the temporary range to include full words
        expandRangeToFullWords(tempRange);

        // Check if the range contains only allowed elements
        if (!containsOnlyAllowedElements(tempRange)) {
            return; // Exit if disallowed elements are found
        }

        // Create the new span for highlighting
        const span = document.createElement('span');
        styleCallback(span);
        span.appendChild(tempRange.extractContents());
        tempRange.insertNode(span);

        // Event listener to remove highlight on click
        span.addEventListener('click', () => {
            span.replaceWith(...span.childNodes);
        });

        // Clean up the original range
        range.collapse(false);
    };

    useEffect(() => {
        const toggleKatexClass = (event: MouseEvent) => {
            const target = event.target as HTMLElement; // Type assertion for target

            // Find the closest element with the specified data-testid
            const katexElement = target.closest('[data-testid="react-katex"]');

            // Toggle class on the found element
            if (katexElement instanceof HTMLElement) {
                katexElement.classList.toggle('increaseKatex');
            }
        };

        // Add the event listener to the document
        document.addEventListener('click', toggleKatexClass);

        // Clean up the event listener
        return () => {
            document.removeEventListener('click', toggleKatexClass);
        };
    }, []);

    const setUnderlined = () => {
        if (selectedText?.rangeCount && selectedText.rangeCount > 0) {
            const range = selectedText.getRangeAt(0);
            if (!range.collapsed) {
                applyStyleToRange(range, (span) => {
                    span.className = `underlineThick`;
                    span.addEventListener('click', function () {
                        this.classList.remove(`underlineThick`);
                    });
                });
                setSelectedText(null);
                setShowBubble(false);
            }
        }
        setSelectedText(null);
        setShowBubble(false);
    };

    const changeFontSize = () => {
        if (selectedText?.rangeCount && selectedText.rangeCount > 0) {
            const range = selectedText.getRangeAt(0);
            if (!range.collapsed) {
                applyStyleToRange(range, (span) => {
                    span.className = `largerFont`;
                    span.addEventListener('click', function () {
                        this.classList.remove(`largerfont`);
                    });
                });
                setSelectedText(null);
                setShowBubble(false);
            }
        }
        setSelectedText(null);
        setShowBubble(false);
    };

    const ttsselect = async () => {
        const selection = window.getSelection();
        if (!selection || selection.rangeCount === 0) {
            console.log('No text is selected.');
            return; // Exit if there is no selection
        }

        const range = selection.getRangeAt(0).cloneRange();

        // Function to expand the range to include full words
        const expandRangeToFullWords = (range: Range) => {
            const startNodeText = range.startContainer.textContent ?? '';
            const endNodeText = range.endContainer.textContent ?? '';

            // Expand the start of the range to the beginning of the word
            let start = range.startOffset;
            while (start > 0 && startNodeText[start - 1] !== ' ') {
                start--;
            }
            range.setStart(range.startContainer, start);

            // Expand the end of the range to the end of the word
            let end = range.endOffset;
            while (
                end < endNodeText.length &&
                endNodeText[end] !== ' ' &&
                endNodeText[end] !== '.' &&
                endNodeText[end] !== ','
            ) {
                end++;
            }
            range.setEnd(range.endContainer, end);
        };

        // Function to check if the range contains only allowed elements
        // Update this function based on your specific requirements
        const containsOnlyAllowedElements = (range: Range): boolean => {
            const walker = document.createTreeWalker(range.commonAncestorContainer, NodeFilter.SHOW_ELEMENT, {
                acceptNode: (node) => {
                    if (node instanceof HTMLElement) {
                        if (excludedClasses.some((className) => node.classList.contains(className))) {
                            return NodeFilter.FILTER_REJECT;
                        }
                        if (allowedTags.includes(node.tagName)) {
                            return NodeFilter.FILTER_ACCEPT;
                        }
                        return NodeFilter.FILTER_REJECT;
                    }
                    return NodeFilter.FILTER_ACCEPT;
                },
            });

            let node = walker.nextNode();
            while (node) {
                if (range.intersectsNode(node)) {
                    return false; // Found a disallowed element
                }
                node = walker.nextNode();
            }
            return true;
        };

        // Expand the temporary range to include full words and check for validity
        expandRangeToFullWords(range);
        if (!containsOnlyAllowedElements(range)) {
            console.log('Selected text contains disallowed elements.');
            return; // Exit if the selection is not valid
        }

        // Extract the selected text
        const selectedTextContent = range.toString();
        console.log('Selected Text:', selectedTextContent);

        try {
            const response = await speechService.getSpeechTokenAsync(
                await AuthHelper.getSKaaSAccessToken(instance, inProgress),
            );

            if (response.isSuccess) {
                await speechService.convertTextToSpeech(
                    response.token,
                    response.region,
                    selectedTextContent, // Assuming selectedTextContent is defined earlier
                    userSettings.voiceGender,
                    userSettings.voiceSpeed,
                    userSettings.voicePitch,
                    userSettings.forceSpeechLang,
                );

                if (speechService.player) {
                    speechService.player.onAudioEnd = () => {
                        setIsPlaying(false);
                        setIsPaused(false);
                        setIsDisabled(false); // Re-enable the play button
                    };
                    setIsPlaying(true);
                }
            } else {
                console.error('Failed to retrieve speech token and region.');
            }

            // Optionally, clear the selection
            selection.removeAllRanges();
        } catch (error) {
            console.error(error); // Handle any errors
        }
    };

    const highlightText = (color: string) => {
        if (selectedText?.rangeCount && selectedText.rangeCount > 0) {
            const range = selectedText.getRangeAt(0);
            if (!range.collapsed) {
                applyStyleToRange(range, (span) => {
                    span.className = `highlighter-${color}`;
                    span.addEventListener('click', function () {
                        this.classList.remove(`highlighter-${color}`);
                    });
                });
                setSelectedText(null);
                setShowBubble(false);
            }
        }
        setSelectedText(null);
        setShowBubble(false);
    };

    //Copy system
    const [isCopied, setIsCopied] = useState<boolean>(false);
    const [isTranslatedCopied, setIsTranslatedCopied] = useState<boolean>(false);

    const handleCopyClick = (isOriginalMessage: boolean) => {
        // Convert markdown to HTML using react-markdown
        let messageContent: string;
        if (isOriginalMessage) {
            messageContent = message.content;
        } else {
            messageContent = translatedText;
        }

        const html: string = ReactDOMServer.renderToStaticMarkup(<ReactMarkdown>{messageContent}</ReactMarkdown>);

        // Create a new DOM element
        const element = document.createElement('div');

        // Set the innerHTML to the converted HTML
        element.innerHTML = html;

        // Append the element to the body
        document.body.appendChild(element);

        // Create a new Range object
        const range = document.createRange();

        // Set the Range to contain the element
        range.selectNode(element);

        // Get the global Selection object
        const selection = window.getSelection();

        if (selection) {
            // Check if selection is not null
            // Remove any existing ranges from the Selection
            selection.removeAllRanges();

            // Add the Range to the Selection
            selection.addRange(range);

            // Copy the selected range to the clipboard
            document.execCommand('copy');

            // Remove the range from the Selection
            selection.removeAllRanges();
        }

        // Remove the element from the body
        document.body.removeChild(element);

        if (isOriginalMessage) {
            setIsCopied(true);

            setTimeout(() => {
                setIsCopied(false);
            }, 3000);
        } else {
            setIsTranslatedCopied(true);

            setTimeout(() => {
                setIsTranslatedCopied(false);
            }, 3000);
        }
    };
    // Word Spacing
    const initialWordSpacing = Number(localStorage.getItem('wordSpacing')) || Number(userSettings.wordSpacing) || 0;
    const [userWordSpacing, setUserWordSpacing] = useState(initialWordSpacing);

    useEffect(() => {
        if (userSettings.wordSpacing) {
            setUserWordSpacing(Number(userSettings.wordSpacing));
        }
    }, [userSettings.wordSpacing]);

    // Font Size
    const [fontSizeSetting, setFontSizeSetting] = useState(() => {
        const savedFontSize = localStorage.getItem('fontSize');
        return savedFontSize ? parseFloat(savedFontSize) : 0.9; // Default fontSize 1em if not set
    });

    useEffect(() => {
        const handleStorageChange = () => {
            const newFontSize = localStorage.getItem('fontSize');
            if (newFontSize) {
                setFontSizeSetting(parseFloat(newFontSize));
            }
        };

        // Add event listener for localStorage changes
        window.addEventListener('storage', handleStorageChange);

        // Cleanup the event listener
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    // Line Height
    const [lineHeightSettings, setLineHeight] = useState(() => {
        const savedLineHeight = localStorage.getItem('lineHeight');
        return savedLineHeight ? parseFloat(savedLineHeight) : 29; // Default fontSize 1em if not set
    });

    useEffect(() => {
        const handleStorageChange = () => {
            const newLineHeight = localStorage.getItem('lineHeight');
            if (newLineHeight) {
                setLineHeight(parseFloat(newLineHeight));
            }
        };

        // Add event listener for localStorage changes
        window.addEventListener('storage', handleStorageChange);

        // Cleanup the event listener
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    let content: JSX.Element;
    if (isBot && message.type === ChatMessageType.Plan) {
        content = <PlanViewer message={message} messageIndex={messageIndex} />;
    } else if (message.type === ChatMessageType.Document) {
        content = <ChatHistoryDocumentContent isMe={isMe} message={message} />;
    } else {
        content = isBot ? (
            message.content.length === 0 ? (
                <TypingIndicator />
            ) : (
                <>
                    <div
                        className="mbody"
                        style={{
                            wordSpacing: `${userWordSpacing}px`,
                            lineHeight: `${lineHeightSettings}px`,
                            fontSize: `${fontSizeSetting}em`,
                        }}
                    >
                        <AudioControlButtons
                            message={message.content}
                            isPlaying={isPlaying}
                            isPaused={isPaused}
                            selectedId={selectedId}
                            isNotTranslateContainer={true}
                            handlePlayClick={handlePlayClick}
                            handlePauseClick={handlePauseClick}
                            handleStopClick={handleStopClick}
                            handleTranslateClick={handleTranslateClick}
                            handleCopyClick={handleCopyClick}
                        />

                        {processMessage(message.content).map((part, index) => {
                            // Assume part.id is a unique identifier; if it's not present, fallback to index
                            const key =
                                typeof part === 'object' && 'code' in part
                                    ? `code-${index}` // Assuming 'code' is not unique
                                    : typeof part === 'object' && 'latex' in part
                                      ? `latex-${index}` // Unique key for LaTeX parts
                                      : `string-${index}`; // Fallback for strings

                            if (typeof part === 'string') {
                                // Render non-code text as markdown
                                return (
                                    <>
                                        {showBubble && (
                                            <div
                                                className="bubble"
                                                style={{ top: bubblePosition.y, left: bubblePosition.x }}
                                            >
                                                <button
                                                    title="Increase font Size"
                                                    className="bubbleIconFirst bubbleFontSize bubbleIconCommon"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        changeFontSize();
                                                    }}
                                                >
                                                    <FontIncrease24Regular />
                                                </button>
                                                <button
                                                    title="Underline Text"
                                                    className="bubbleIcon bubbleFontSize bubbleIconCommon"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setUnderlined();
                                                    }}
                                                >
                                                    <TextUnderline24Filled />
                                                </button>
                                                <button
                                                    title="Highlight Pink"
                                                    className="bubbleIcon bubbleIconPink bubbleIconCommon"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        highlightText('pink');
                                                    }}
                                                >
                                                    <Highlight24Regular />
                                                </button>

                                                <button
                                                    title="Highlight Blue"
                                                    className="bubbleIcon bubbleIconBlue bubbleIconCommon"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        highlightText('blue');
                                                    }}
                                                >
                                                    <Highlight24Regular />
                                                </button>
                                                <button
                                                    title="Highlight Yellow"
                                                    className="bubbleIcon bubbleIconYellow bubbleIconCommon"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        highlightText('yellow');
                                                    }}
                                                >
                                                    <Highlight24Regular />
                                                </button>
                                                <button
                                                    title="Highlight Black"
                                                    className="bubbleIcon bubbleIconBlack bubbleIconCommon"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        highlightText('black');
                                                    }}
                                                >
                                                    <Highlight24Regular />
                                                </button>
                                                <button
                                                    title="Speak This"
                                                    className="bubbleIconLast bubbleFontSize bubbleIconCommon"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        void ttsselect();
                                                    }}
                                                >
                                                    <Chat24Regular />
                                                </button>
                                            </div>
                                        )}
                                        <ReactMarkdown
                                            key={key}
                                            remarkPlugins={[remarkGfm]}
                                            components={{
                                                code({ className, children, ...props }) {
                                                    const isInline = className?.includes('inline');
                                                    if (isInline) {
                                                        return (
                                                            <span
                                                                className="inlinecode"
                                                                onMouseEnter={() => {
                                                                    handleHover(key, true);
                                                                }}
                                                                onMouseLeave={() => {
                                                                    handleHover(key, false);
                                                                }}
                                                            >
                                                                {children}
                                                            </span>
                                                        );
                                                    } else {
                                                        return (
                                                            <code className={className} {...props}>
                                                                {children}
                                                            </code>
                                                        );
                                                    }
                                                },
                                                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                                p: CustomParagraph as any,
                                            }}
                                        >
                                            {part}
                                        </ReactMarkdown>
                                    </>
                                );
                            } else if (typeof part === 'object' && 'code' in part && part.inline) {
                                // Render inline code
                                return (
                                    <span
                                        key={key}
                                        className="inlinecode"
                                        onMouseEnter={() => {
                                            handleHover(part.code, true);
                                        }}
                                        onMouseLeave={() => {
                                            handleHover(part.code, false);
                                        }}
                                        onClick={() => {
                                            handleClick(part.code);
                                        }}
                                        // onMouseEnter={() => {
                                        //     handleHover(part.code, true);
                                        // }}
                                        // onMouseLeave={() => {
                                        //     handleHover(part.code, false);
                                        // }}
                                    >
                                        {part.code}
                                    </span>
                                );
                            } else if (typeof part === 'object' && 'code' in part) {
                                // Render code blocks with syntax highlighting
                                return (
                                    <>
                                        <div
                                            key={key}
                                            data-code-key={`${message.id}-${key}`}
                                            className={`chat-codeBlockMainC key=${key}-container`}
                                        >
                                            <div className="codeBlockHeaderParent" key={`${key}-header`}>
                                                <div className="codeCopyButton">
                                                    <div>
                                                        <button
                                                            onClick={() => {
                                                                onOpenTab({
                                                                    key: `${message.id}-${key}`,
                                                                    language: part.language,
                                                                    messageid: `${message.id}-${key}`,
                                                                    code: part.code,
                                                                });
                                                            }}
                                                            title="UT Verse CodePilot"
                                                            className="codeButtons"
                                                        >
                                                            <SplitHorizontal20Regular />
                                                        </button>
                                                        {!(
                                                            part.language === 'POWERSHELL' ||
                                                            part.language === 'DOS' ||
                                                            part.language === 'TERMINAL'
                                                        ) && (
                                                            <button
                                                                onClick={toggleTextWrap}
                                                                title="Text Wrap"
                                                                className="codeButtons"
                                                            >
                                                                {textWrapSetting ? (
                                                                    <TextWrap20Filled />
                                                                ) : (
                                                                    <TextWrap20Regular />
                                                                )}
                                                            </button>
                                                        )}

                                                        {!(
                                                            part.language === 'POWERSHELL' ||
                                                            part.language === 'DOS' ||
                                                            part.language === 'TERMINAL'
                                                        ) && (
                                                            <button
                                                                onClick={toggleLineNumbers}
                                                                title="Line Numbers"
                                                                className="codeButtons"
                                                            >
                                                                {showLineNumbers ? (
                                                                    <NumberSymbol20Filled />
                                                                ) : (
                                                                    <NumberSymbol20Regular />
                                                                )}
                                                            </button>
                                                        )}

                                                        <button
                                                            onClick={() => {
                                                                handleCopyClickCode(part.code.trim());
                                                            }}
                                                            className="codeButtons"
                                                        >
                                                            {isCopiedCode ? (
                                                                <>
                                                                    <div className="copiedBubble">Copied!</div>
                                                                    <Copy20Filled />
                                                                </>
                                                            ) : (
                                                                <Copy20Regular />
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`codeBlockHeader ${
                                                        getPrismLanguage(part.language ?? 'none') === 'powershell'
                                                            ? 'powershellHeader'
                                                            : getPrismLanguage(part.language ?? 'none') === 'batch' ||
                                                                getPrismLanguage(part.language ?? 'none') === 'shell'
                                                              ? 'terminalHeader'
                                                              : ''
                                                    }`}
                                                >
                                                    {part.language ? part.language.toUpperCase() : 'PLAINTEXT'}
                                                </div>
                                            </div>
                                            <SyntaxHighlighter
                                                style={'prism-themes/themes/prism-vsc-dark-plus.css'}
                                                key={key}
                                                className="main-code-block"
                                                language={part.language ?? 'none'}
                                                showLineNumbers={
                                                    !(
                                                        part.language === 'POWERSHELL' ||
                                                        part.language === 'DOS' ||
                                                        part.language === 'TERMINAL'
                                                    ) && showLineNumbers
                                                }
                                                lineProps={{
                                                    style: {
                                                        wordBreak:
                                                            part.language === 'POWERSHELL' ||
                                                            part.language === 'DOS' ||
                                                            part.language === 'TERMINAL'
                                                                ? 'normal'
                                                                : 'break-all',
                                                        whiteSpace:
                                                            part.language === 'POWERSHELL' ||
                                                            part.language === 'DOS' ||
                                                            part.language === 'TERMINAL'
                                                                ? 'nowrap'
                                                                : 'pre-wrap',
                                                    },
                                                }}
                                                wrapLines={
                                                    !(
                                                        part.language === 'POWERSHELL' ||
                                                        part.language === 'DOS' ||
                                                        part.language === 'TERMINAL'
                                                    ) && textWrapSetting
                                                }
                                            >
                                                {part.code.trim()}
                                            </SyntaxHighlighter>
                                        </div>
                                    </>
                                );
                            } else if (typeof part === 'object' && 'latex' in part) {
                                return part.type === 'inline' ? (
                                    <InlineMath key={`inline-latex-${index}`} math={part.latex} />
                                ) : (
                                    <BlockMath key={`block-latex-${index}`} math={part.latex} />
                                );
                            } else {
                                // Fallback rendering for any other content
                                return <div key={key}>{part}</div>;
                            }
                        })}
                        {translatedText && (
                            <div className="translatedContainer">
                                <div>
                                    <div className="translatedTitle">
                                        Translation:{' '}
                                        {isTranslatedCopied && (
                                            <span className={classes.copiedIndicator}>Copied to clipboard!</span>
                                        )}
                                    </div>
                                    <AudioControlButtons
                                        message={translatedText}
                                        isPlaying={isPlaying}
                                        isPaused={isPaused}
                                        selectedId={selectedId}
                                        isNotTranslateContainer={false}
                                        handlePlayClick={handlePlayClick}
                                        handlePauseClick={handlePauseClick}
                                        handleStopClick={handleStopClick}
                                        handleTranslateClick={handleTranslateClick}
                                        handleCopyClick={handleCopyClick}
                                    />
                                </div>
                                {processMessage(translatedText).map((part, index) => {
                                    // Assume part.id is a unique identifier; if it's not present, fallback to index
                                    const key =
                                        typeof part === 'object' && 'code' in part
                                            ? `code-${index}` // Assuming 'code' is not unique
                                            : typeof part === 'object' && 'latex' in part
                                              ? `latex-${index}` // Unique key for LaTeX parts
                                              : `string-${index}`; // Fallback for strings

                                    if (typeof part === 'string') {
                                        // Render non-code text as markdown
                                        return (
                                            <ReactMarkdown
                                                key={key} // Use the unique identifier as key
                                                remarkPlugins={[remarkGfm]}
                                                components={{
                                                    code({ className, children, ...props }) {
                                                        const isInline = className?.includes('inline');
                                                        if (isInline) {
                                                            return <span className="inlinecode">{children}</span>;
                                                        } else {
                                                            return (
                                                                <code className={className} {...props}>
                                                                    {children}
                                                                </code>
                                                            );
                                                        }
                                                    },
                                                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                                    p: CustomParagraph as any,
                                                }}
                                            >
                                                {part}
                                            </ReactMarkdown>
                                        );
                                    } else if (typeof part === 'object' && 'code' in part && part.inline) {
                                        // Render inline code
                                        return (
                                            <span
                                                key={key}
                                                className="inlinecode"
                                                onMouseEnter={() => {
                                                    handleHover(part.code, true);
                                                }}
                                                onMouseLeave={() => {
                                                    handleHover(part.code, false);
                                                }}
                                                // onMouseEnter={() => {
                                                //     handleHover(part.code, true);
                                                // }}
                                                // onMouseLeave={() => {
                                                //     handleHover(part.code, false);
                                                // }}
                                            >
                                                {part.code}
                                            </span>
                                        );
                                    } else if (typeof part === 'object' && 'code' in part) {
                                        // Render code blocks with syntax highlighting
                                        return (
                                            <>
                                                <div className="codeBlockHeaderParent" key={`${key}-header`}>
                                                    <div className="codeCopyButton">
                                                        <div>
                                                            {/* <button onClick={toggleHighlight}>
                                                                {highlightActive
                                                                    ? 'Disable Highlight'
                                                                    : 'Enable Highlight'}
                                                            </button> */}
                                                            {!(
                                                                part.language === 'POWERSHELL' ||
                                                                part.language === 'PS' ||
                                                                part.language === 'DOS' ||
                                                                part.language === 'TERMINAL'
                                                            ) && (
                                                                <button
                                                                    onClick={toggleTextWrap}
                                                                    title="Text Wrap"
                                                                    className="codeButtons"
                                                                >
                                                                    {textWrapSetting ? (
                                                                        <TextWrap20Filled />
                                                                    ) : (
                                                                        <TextWrap20Regular />
                                                                    )}
                                                                </button>
                                                            )}

                                                            {!(
                                                                part.language === 'POWERSHELL' ||
                                                                part.language === 'PS' ||
                                                                part.language === 'DOS' ||
                                                                part.language === 'TERMINAL'
                                                            ) && (
                                                                <button
                                                                    onClick={toggleLineNumbers}
                                                                    title="Line Numbers"
                                                                    className="codeButtons"
                                                                >
                                                                    {showLineNumbers ? (
                                                                        <NumberSymbol20Filled />
                                                                    ) : (
                                                                        <NumberSymbol20Regular />
                                                                    )}
                                                                </button>
                                                            )}

                                                            <button
                                                                onClick={() => {
                                                                    handleCopyClickCode(part.code.trim());
                                                                }}
                                                                className="codeButtons"
                                                            >
                                                                {isCopiedCode ? (
                                                                    <>
                                                                        <div className="copiedBubble">Copied!</div>
                                                                        <Copy20Filled />
                                                                    </>
                                                                ) : (
                                                                    <Copy20Regular />
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`codeBlockHeader ${
                                                            getPrismLanguage(part.language ?? 'none') === 'powershell'
                                                                ? 'powershellHeader'
                                                                : getPrismLanguage(part.language ?? 'none') ===
                                                                        'batch' ||
                                                                    getPrismLanguage(part.language ?? 'none') ===
                                                                        'shell'
                                                                  ? 'terminalHeader'
                                                                  : ''
                                                        }`}
                                                    >
                                                        {part.language ? part.language.toUpperCase() : 'PLAINTEXT'}
                                                    </div>
                                                </div>
                                                <SyntaxHighlighter
                                                    style={'prism-themes/themes/prism-vsc-dark-plus.css'}
                                                    key={key}
                                                    className="main-code-block"
                                                    language={part.language ?? 'none'}
                                                    showLineNumbers={
                                                        !(
                                                            part.language === 'POWERSHELL' ||
                                                            part.language === 'DOS' ||
                                                            part.language === 'TERMINAL'
                                                        ) && showLineNumbers
                                                    }
                                                    lineProps={{
                                                        style: {
                                                            wordBreak:
                                                                part.language === 'POWERSHELL' ||
                                                                part.language === 'DOS' ||
                                                                part.language === 'TERMINAL'
                                                                    ? 'normal'
                                                                    : 'break-all',
                                                            whiteSpace:
                                                                part.language === 'POWERSHELL' ||
                                                                part.language === 'DOS' ||
                                                                part.language === 'TERMINAL'
                                                                    ? 'nowrap'
                                                                    : 'pre-wrap',
                                                        },
                                                    }}
                                                    wrapLines={
                                                        !(
                                                            part.language === 'POWERSHELL' ||
                                                            part.language === 'DOS' ||
                                                            part.language === 'TERMINAL'
                                                        ) && textWrapSetting
                                                    }
                                                >
                                                    {part.code.trim()}
                                                </SyntaxHighlighter>
                                            </>
                                        );
                                    } else if (typeof part === 'object' && 'latex' in part) {
                                        return part.type === 'inline' ? (
                                            <InlineMath key={`inline-latex-${index}`} math={part.latex} />
                                        ) : (
                                            <BlockMath key={`block-latex-${index}`} math={part.latex} />
                                        ); // Use an appropriate component for block LaTeX
                                    } else {
                                        // Fallback rendering for any other content
                                        return (
                                            <div key={key}>
                                                {typeof part === 'object' ? 'Unsupported content type' : part}
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        )}
                    </div>
                </>
            )
        ) : (
            <>
                <div
                    className="mbody"
                    style={{
                        wordSpacing: `${userWordSpacing}px`,
                        lineHeight: `${lineHeightSettings}px`,
                        fontSize: `${fontSizeSetting}em`,
                    }}
                >
                    <AudioControlButtons
                        message={message.content}
                        isPlaying={isPlaying}
                        isPaused={isPaused}
                        selectedId={selectedId}
                        isNotTranslateContainer={true}
                        handlePlayClick={handlePlayClick}
                        handlePauseClick={handlePauseClick}
                        handleStopClick={handleStopClick}
                        handleTranslateClick={handleTranslateClick}
                        handleCopyClick={handleCopyClick}
                    />
                    {processMessage(message.content).map((part, index) => {
                        const key =
                            typeof part === 'object' && 'code' in part
                                ? `code-${index}` // Assuming 'code' is not unique
                                : typeof part === 'object' && 'latex' in part
                                  ? `latex-${index}` // Unique key for LaTeX parts
                                  : `string-${index}`; // Fallback for strings

                        if (typeof part === 'string') {
                            // Render non-code text as markdown
                            return (
                                <ReactMarkdown key={index} remarkPlugins={[remarkGfm]}>
                                    {part}
                                </ReactMarkdown>
                            );
                        } else if (typeof part === 'object' && 'code' in part && part.inline) {
                            // Render inline code
                            return (
                                <span
                                    className="inlinecode"
                                    key={index}
                                    onMouseEnter={() => {
                                        handleHover(part.code, true);
                                    }}
                                    onMouseLeave={() => {
                                        handleHover(part.code, false);
                                    }}
                                    // onMouseEnter={() => {
                                    //     handleHover(part.code, true);
                                    // }}
                                    // onMouseLeave={() => {
                                    //     handleHover(part.code, false);
                                    // }}
                                >
                                    {part.code}
                                </span>
                            );
                        } else if (typeof part === 'object' && 'code' in part) {
                            // Render code blocks with syntax highlighting
                            return (
                                <>
                                    <div className="codeBlockHeaderParent">
                                        <div className="codeCopyButton">
                                            <div>
                                                <button
                                                    onClick={() => {
                                                        onOpenTab({
                                                            key: `${message.id}-${key}`,
                                                            language: part.language,
                                                            messageid: `${message.id}-${key}`,
                                                            code: part.code,
                                                        });
                                                    }}
                                                    title="UT Verse CodePilot"
                                                    className="codeButtons"
                                                >
                                                    <SplitHorizontal20Regular />
                                                </button>
                                                {!(
                                                    part.language === 'POWERSHELL' ||
                                                    part.language === 'DOS' ||
                                                    part.language === 'TERMINAL'
                                                ) && (
                                                    <button
                                                        onClick={toggleTextWrap}
                                                        title="Text Wrap"
                                                        className="codeButtons"
                                                    >
                                                        {textWrapSetting ? <TextWrap20Filled /> : <TextWrap20Regular />}
                                                    </button>
                                                )}

                                                {!(
                                                    part.language === 'POWERSHELL' ||
                                                    part.language === 'DOS' ||
                                                    part.language === 'TERMINAL'
                                                ) && (
                                                    <button
                                                        onClick={toggleLineNumbers}
                                                        title="Line Numbers"
                                                        className="codeButtons"
                                                    >
                                                        {showLineNumbers ? (
                                                            <NumberSymbol20Filled />
                                                        ) : (
                                                            <NumberSymbol20Regular />
                                                        )}
                                                    </button>
                                                )}

                                                <button
                                                    onClick={() => {
                                                        handleCopyClickCode(part.code.trim());
                                                    }}
                                                    className="codeButtons"
                                                >
                                                    {isCopiedCode ? (
                                                        <>
                                                            <div className="copiedBubble">Copied!</div>
                                                            <Copy20Filled />
                                                        </>
                                                    ) : (
                                                        <Copy20Regular />
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                        <div
                                            className={`codeBlockHeader ${
                                                getPrismLanguage(part.language ?? 'none') === 'powershell'
                                                    ? 'powershellHeader'
                                                    : getPrismLanguage(part.language ?? 'none') === 'batch' ||
                                                        getPrismLanguage(part.language ?? 'none') === 'shell'
                                                      ? 'terminalHeader'
                                                      : ''
                                            }`}
                                        >
                                            {part.language ? part.language.toUpperCase() : 'PLAINTEXT'}
                                        </div>
                                    </div>
                                    <SyntaxHighlighter
                                        style={'prism-themes/themes/prism-vsc-dark-plus.css'}
                                        key={index}
                                        className="main-code-block"
                                        language={part.language ?? 'none'} // Default to 'none' if no language is provided
                                        showLineNumbers={showLineNumbers} // Optional: if you want to show line numbers
                                        lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}
                                        wrapLines={textWrapSetting}
                                    >
                                        {part.code.trim()}
                                    </SyntaxHighlighter>
                                </>
                            );
                        } else if (typeof part === 'object' && 'latex' in part) {
                            return part.type === 'inline' ? (
                                <InlineMath key={`inline-latex-${index}`} math={part.latex} />
                            ) : (
                                <BlockMath key={`block-latex-${index}`} math={part.latex} />
                            ); // Use an appropriate component for block LaTeX
                        } else {
                            // Fallback rendering for any other content
                            return <div key={key}>{typeof part === 'object' ? 'Unsupported content type' : part}</div>;
                        }
                    })}

                    {translatedText && (
                        <div className="translatedContainer">
                            <div>
                                <div className="translatedTitle">
                                    Translation:{' '}
                                    {isTranslatedCopied && (
                                        <span className={classes.copiedIndicator}>Copied to clipboard!</span>
                                    )}
                                </div>
                                <AudioControlButtons
                                    message={translatedText}
                                    isPlaying={isPlaying}
                                    isPaused={isPaused}
                                    selectedId={selectedId}
                                    isNotTranslateContainer={false}
                                    handlePlayClick={handlePlayClick}
                                    handlePauseClick={handlePauseClick}
                                    handleStopClick={handleStopClick}
                                    handleTranslateClick={handleTranslateClick}
                                    handleCopyClick={handleCopyClick}
                                />
                            </div>
                            {processMessage(translatedText).map((part, index) => {
                                const key =
                                    typeof part === 'object' && 'code' in part
                                        ? `code-${index}` // Assuming 'code' is not unique
                                        : typeof part === 'object' && 'latex' in part
                                          ? `latex-${index}` // Unique key for LaTeX parts
                                          : `string-${index}`; // Fallback for strings

                                if (typeof part === 'string') {
                                    // Render non-code text as markdown
                                    return (
                                        <ReactMarkdown key={index} remarkPlugins={[remarkGfm]}>
                                            {part}
                                        </ReactMarkdown>
                                    );
                                } else if (typeof part === 'object' && 'code' in part && part.inline) {
                                    // Render inline code
                                    return (
                                        <span className="inlinecode" key={index}>
                                            {part.code}
                                        </span>
                                    );
                                } else if (typeof part === 'object' && 'code' in part) {
                                    // Render code blocks with syntax highlighting

                                    return (
                                        <>
                                            <div className="codeBlockHeaderParent">
                                                <div className="codeCopyButton">
                                                    <div>
                                                        <button
                                                            onClick={() => {
                                                                onOpenTab({
                                                                    key: `${message.id}-${key}`,
                                                                    language: part.language,
                                                                    messageid: `${message.id}-${key}`,
                                                                    code: part.code,
                                                                });
                                                            }}
                                                            title="UT Verse CodePilot"
                                                            className="codeButtons"
                                                        >
                                                            <SplitHorizontal20Regular />
                                                        </button>
                                                        {!(
                                                            part.language === 'POWERSHELL' ||
                                                            part.language === 'DOS' ||
                                                            part.language === 'TERMINAL'
                                                        ) && (
                                                            <button
                                                                onClick={toggleTextWrap}
                                                                title="Text Wrap"
                                                                className="codeButtons"
                                                            >
                                                                {textWrapSetting ? (
                                                                    <TextWrap20Filled />
                                                                ) : (
                                                                    <TextWrap20Regular />
                                                                )}
                                                            </button>
                                                        )}

                                                        {!(
                                                            part.language === 'POWERSHELL' ||
                                                            part.language === 'DOS' ||
                                                            part.language === 'TERMINAL'
                                                        ) && (
                                                            <button
                                                                onClick={toggleLineNumbers}
                                                                title="Line Numbers"
                                                                className="codeButtons"
                                                            >
                                                                {showLineNumbers ? (
                                                                    <NumberSymbol20Filled />
                                                                ) : (
                                                                    <NumberSymbol20Regular />
                                                                )}
                                                            </button>
                                                        )}

                                                        <button
                                                            onClick={() => {
                                                                handleCopyClickCode(part.code.trim());
                                                            }}
                                                            className="codeButtons"
                                                        >
                                                            {isCopiedCode ? (
                                                                <>
                                                                    <div className="copiedBubble">Copied!</div>
                                                                    <Copy20Filled />
                                                                </>
                                                            ) : (
                                                                <Copy20Regular />
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`codeBlockHeader ${
                                                        part.language === 'POWERSHELL'
                                                            ? 'powershellHeader'
                                                            : part.language === 'DOS' || part.language === 'TERMINAL'
                                                              ? 'terminalHeader'
                                                              : ''
                                                    }`}
                                                >
                                                    {part.language ? part.language.toUpperCase() : 'PLAINTEXT'}
                                                </div>
                                            </div>
                                            <SyntaxHighlighter
                                                style={'prism-themes/themes/prism-vsc-dark-plus.css'}
                                                key={index}
                                                className="main-code-block"
                                                language={part.language ?? 'none'} // Default to 'none' if no language is provided
                                                showLineNumbers={showLineNumbers} // Optional: if you want to show line numbers
                                                lineProps={{
                                                    style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' },
                                                }}
                                                wrapLines={textWrapSetting}
                                            >
                                                {part.code.trim()}
                                            </SyntaxHighlighter>
                                        </>
                                    );
                                } else if (typeof part === 'object' && 'latex' in part) {
                                    return part.type === 'inline' ? (
                                        <InlineMath key={`inline-latex-${index}`} math={part.latex} />
                                    ) : (
                                        <BlockMath key={`block-latex-${index}`} math={part.latex} />
                                    ); // Use an appropriate component for block LaTeX
                                } else {
                                    // Fallback rendering for any other content
                                    return (
                                        <div key={key}>
                                            {typeof part === 'object' ? 'Unsupported content type' : part}
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    )}
                </div>
            </>
        );
    }

    return (
        <div
            className={isMe ? mergeClasses(classes.root, classes.alignEnd) : classes.root}
            // The following data attributes are needed for CI and testing
            data-testid={`chat-history-item-${messageIndex}`}
            data-username={fullName}
        >
            <Persona
                className={classes.persona}
                avatar={avatar}
                presence={!isMe ? { status: 'available' } : undefined}
            />
            <div className={isMe ? mergeClasses(classes.item, classes.me) : classes.item}>
                <div className={classes.header}>
                    <Text weight="semibold">{fullName}</Text>
                    <Text className={`${classes.time} msgtime`}>{timestampToDateString(message.timestamp, true)}</Text>
                    {isCopied && <div className={classes.copiedIndicator}>Copied to clipboard!</div>}
                </div>
                {content}
                <div className={classes.controls}>
                    {/* {message.citations && message.citations.length > 0 && (
                        <ToggleButton
                            appearance="subtle"
                            checked={showCitationCards}
                            className={classes.citationButton}
                            icon={showCitationCards ? <ChevronUp20Regular /> : <ChevronDown20Regular />}
                            iconPosition="after"
                            onClick={() => {
                                setShowCitationCards(!showCitationCards);
                            }}
                            size="small"
                        >
                            {`${message.citations.length} ${message.citations.length === 1 ? 'citation' : 'citations'}`}
                        </ToggleButton>
                    )} */}
                    {showShowRLHFMessage && (
                        <div className={classes.rlhf}>{<UserFeedbackActions messageIndex={messageIndex} />}</div>
                    )}
                </div>
                {/* {showCitationCards && <CitationCards message={message} />} */}
            </div>
            {features[FeatureKeys.RLHF].enabled && message.userFeedback === UserFeedback.Positive && (
                <ThumbLikeFilled color="gray" />
            )}
            {features[FeatureKeys.RLHF].enabled && message.userFeedback === UserFeedback.Negative && (
                <ThumbDislikeFilled color="gray" />
            )}
        </div>
    );
};
