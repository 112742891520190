import { AccountInfo } from '@azure/msal-common';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { FluentProvider, Subtitle1, makeStyles, shorthands, tokens } from '@fluentui/react-components';

import * as React from 'react';
import { useEffect } from 'react';
import { AppContextProvider } from './AppContext';
import { UserSettingsMenu } from './components/header/UserSettingsMenu';
// import { PluginGallery } from './components/open-api-plugins/PluginGallery';
import { BackendProbe, ChatView, Error, Loading, Login } from './components/views';
import { AuthHelper } from './libs/auth/AuthHelper';
import { useChat, useFile } from './libs/hooks';
import { AlertType } from './libs/models/AlertType';
import { useAppDispatch, useAppSelector } from './redux/app/hooks';
import { RootState } from './redux/app/store';
import { FeatureKeys } from './redux/features/app/AppState';
import { addAlert, setActiveUserInfo, setServiceInfo } from './redux/features/app/appSlice';
import { semanticKernelDarkTheme, semanticKernelLightTheme } from './styles';

export const useClasses = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        ...shorthands.overflow('hidden'),
    },
    header: {
        alignItems: 'center',
        backgroundColor: '#313e48',
        color: tokens.colorNeutralForegroundOnBrand,
        display: 'flex',
        '& h1': {
            paddingLeft: tokens.spacingHorizontalXL,
            display: 'flex',
        },
        height: '48px',
        justifyContent: 'space-between',
        width: '100%',
        fontSize: '1rem',
    },
    headerTop: {
        position: 'relative',
        backgroundColor: '#ff8200',
        left: 0,
        right: 0,
        top: 0,
        height: '0.45rem',
    },
    persona: {
        marginRight: tokens.spacingHorizontalXXL,
    },
    cornerItems: {
        display: 'flex',
        ...shorthands.gap(tokens.spacingHorizontalS),
    },
});

enum AppState {
    ProbeForBackend,
    SettingUserInfo,
    ErrorLoadingChats,
    ErrorLoadingUserInfo,
    LoadingChats,
    Chat,
    SigningOut,
}

const App = () => {
    const classes = useClasses();

    const [appState, setAppState] = React.useState(AppState.ProbeForBackend);
    const dispatch = useAppDispatch();

    const { instance, inProgress } = useMsal();
    const { features, isMaintenance } = useAppSelector((state: RootState) => state.app);
    const isAuthenticated = useIsAuthenticated();

    const chat = useChat();
    const file = useFile();

    type ExtendedAccountInfo = AccountInfo & {
        department: string;
        email: string;
        mail: string;
        manager: string;
        photo: string;
        activity: string;
        availability: string;
    };

    useEffect(() => {
        if (isMaintenance && appState !== AppState.ProbeForBackend) {
            setAppState(AppState.ProbeForBackend);
            return;
        }

        if (isAuthenticated && appState === AppState.SettingUserInfo) {
            const accountOrNull: AccountInfo | null = instance.getActiveAccount();
            const account: ExtendedAccountInfo | undefined = accountOrNull
                ? {
                      ...accountOrNull, // Spread the properties from AccountInfo
                      department: '', // Initialize the department, manager, and photo properties
                      email: '',
                      mail: '',
                      manager: '',
                      photo: '',
                      activity: '',
                      availability: '',
                  }
                : undefined;
            if (!account) {
                setAppState(AppState.ErrorLoadingUserInfo);
            } else {
                dispatch(
                    setActiveUserInfo({
                        id: `${account.localAccountId}.${account.tenantId}`,
                        emailAddress: account.username, // username is the email address
                        email: account.email,
                        username: account.name ?? account.username,
                        manager: account.manager,
                        department: account.department,
                        activity: account.activity,
                        availability: account.availability,
                        photo: account.photo,
                    }),
                );

                // Privacy disclaimer for internal Microsoft users
                if (account.username.split('@')[1] === 'microsoft.com') {
                    dispatch(
                        addAlert({
                            message:
                                'By using this application, you agree to protect sensitive data, not store it in chat, and allow chat history collection for service improvements. This tool is for internal use only.',
                            type: AlertType.Info,
                        }),
                    );
                }

                setAppState(AppState.LoadingChats);
            }
        }

        if ((isAuthenticated || !AuthHelper.isAuthAAD()) && appState === AppState.LoadingChats) {
            void Promise.all([
                // Load all chats from memory
                chat
                    .loadChats()
                    .then(() => {
                        setAppState(AppState.Chat);
                    })
                    .catch(() => {
                        setAppState(AppState.ErrorLoadingChats);
                    }),

                // Check if content safety is enabled
                file.getContentSafetyStatus(),

                // Load service information
                chat.getServiceInfo().then((serviceInfo) => {
                    if (serviceInfo) {
                        dispatch(setServiceInfo(serviceInfo));
                    }
                }),
            ]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instance, inProgress, isAuthenticated, appState, isMaintenance]);

    const content = <Chat classes={classes} appState={appState} setAppState={setAppState} />;
    return (
        <AppContextProvider>
            <FluentProvider
                className="app-container"
                theme={features[FeatureKeys.DarkMode].enabled ? semanticKernelDarkTheme : semanticKernelLightTheme}
            >
                {AuthHelper.isAuthAAD() ? (
                    <>
                        <UnauthenticatedTemplate>
                            <div className={classes.container}>
                                <div className={classes.header}>
                                    <Subtitle1 as="h1">UT Verse Chat</Subtitle1>
                                </div>
                                {appState === AppState.SigningOut && <Loading text="Signing you out..." />}
                                {appState !== AppState.SigningOut && <Login />}
                            </div>
                        </UnauthenticatedTemplate>
                        <AuthenticatedTemplate>{content}</AuthenticatedTemplate>
                    </>
                ) : (
                    content
                )}
            </FluentProvider>
        </AppContextProvider>
    );
};

const Chat = ({
    classes,
    appState,
    setAppState,
}: {
    classes: ReturnType<typeof useClasses>;
    appState: AppState;
    setAppState: (state: AppState) => void;
}) => {
    const onBackendFound = React.useCallback(() => {
        setAppState(
            AuthHelper.isAuthAAD()
                ? // if AAD is enabled, we need to set the active account before loading chats
                  AppState.SettingUserInfo
                : // otherwise, we can load chats immediately
                  AppState.LoadingChats,
        );
    }, [setAppState]);
    return (
        <AppContextProvider>
            <div className={classes.container}>
                <div className={classes.header}>
                    <Subtitle1 as="h1">UT Verse Chat</Subtitle1>
                    {appState > AppState.SettingUserInfo && (
                        <div className={classes.cornerItems}>
                            <div className={classes.cornerItems}>
                                <UserSettingsMenu
                                    setLoadingState={() => {
                                        setAppState(AppState.SigningOut);
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
                {appState === AppState.ProbeForBackend && <BackendProbe onBackendFound={onBackendFound} />}
                {appState === AppState.SettingUserInfo && (
                    <Loading text={'Hang tight while we fetch your information...'} />
                )}
                {appState === AppState.ErrorLoadingUserInfo && (
                    <Error text={'Unable to load user info. Please try signing out and signing back in.'} />
                )}
                {appState === AppState.ErrorLoadingChats && (
                    <Error text={'Unable to load chats. Please try refreshing the page.'} />
                )}
                {appState === AppState.LoadingChats && <Loading text="So far so good, just a bit longer..." />}
                {appState === AppState.Chat && <ChatView />}
            </div>
        </AppContextProvider>
    );
};

export default App;
