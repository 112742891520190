import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { getFriendlyChatName } from '../../../libs/hooks/useChat';
import { ChatMessageType } from '../../../libs/models/ChatMessage';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { Conversations } from '../../../redux/features/conversations/ConversationsState';
//import { Breakpoints } from '../../../styles';
import { useEffect, useState } from 'react';
import { ChatListItem } from './ChatListItem';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ...shorthands.gap(tokens.spacingVerticalXXS),
        paddingBottom: tokens.spacingVerticalXS,
    },
    header: {
        position: 'relative',
        marginTop: 0,
        left: 0,
        right: 0,
        width: '100%',
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '55px',
        paddingRight: tokens.spacingHorizontalXL,
        fontWeight: tokens.fontWeightBold,
        fontSize: tokens.fontSizeBase300,
        color: '#fff',
        backgroundColor: '#313e48',
        borderBottomColor: '#ff8200',
        borderBottomWidth: '4px',
        borderBottomStyle: 'solid',
    },
});

interface IChatListSectionProps {
    header?: string;
    conversations: Conversations;
}

export const ChatListSection: React.FC<IChatListSectionProps> = ({ header, conversations }) => {
    const classes = useClasses();
    const { selectedId } = useAppSelector((state: RootState) => state.conversations);
    const keys = Object.keys(conversations);
    const userId = useAppSelector((state) => state.app.activeUserInfo?.id);

    //console.log(useAppSelector(state => state.someStatePath?.users?.[currentUserId]?.id) ?? null;)
    // const currentUserData = useAppSelector((state) => state.usersState.users[currentUserId]);
    // const userId = currentUserData ? currentUserData.id : null;

    const [currentFont, setCurrentFont] = useState(localStorage.getItem('selectedFont') ?? 'fontMontserrat');

    // Effect to update the font based on localStorage changes
    useEffect(() => {
        const updateFont = (event: CustomEvent<string>) => {
            setCurrentFont(event.detail);
        };

        window.addEventListener('fontChange', updateFont as EventListener);

        // Initialize the font
        const storedFont = localStorage.getItem('selectedFont') ?? 'fontMontserrat';
        setCurrentFont(storedFont);

        return () => {
            window.removeEventListener('fontChange', updateFont as EventListener);
        };
    }, []);

    return keys.length > 0 ? (
        <div className={classes.root}>
            <div className={`${classes.header} ${currentFont}`}>{header}</div>
            {keys.map((id) => {
                const convo = conversations[id];
                const messages = convo.messages;
                const lastMessage = messages[convo.messages.length - 1];
                const isSelected = id === selectedId;
                let itsMe = false;
                if (convo.users[0].id == userId) {
                    itsMe = true;
                }

                return (
                    <ChatListItem
                        key={id} // key moved here
                        id={id}
                        itsMe={itsMe}
                        isSelected={isSelected}
                        header={`${getFriendlyChatName(convo).substring(0, 19)} ${
                            convo.users.length > 1 ? '+' + convo.users.length : ''
                        }`}
                        timestamp={convo.lastUpdatedTimestamp ?? lastMessage.timestamp}
                        preview={
                            messages.length > 0
                                ? lastMessage.type === ChatMessageType.Document
                                    ? 'Sent a file'
                                    : lastMessage.type === ChatMessageType.Plan
                                    ? 'Click to view proposed plan'
                                    : lastMessage.content
                                : 'Click to start the chat'
                        }
                        botProfilePicture={convo.botProfilePicture}
                    />
                );
            })}
        </div>
    ) : null;
};
